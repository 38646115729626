import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import PageHeader from "../../components/common/PageHeader";
import { useXhr } from "hooks/useXhr";
import { useAboutVideoContext } from "hooks/useAboutVideoContext";
import { Button, Spinner } from "react-bootstrap";

function AboutVideo() {
    //States
    const [status, setStatus] = useState(false);
    const [isUpdate, setIsUpdate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [lang, setLang] = useState(null);
    const [error, setError] = useState({
        field: null,
        note: null
    });

    //Hooks
    const { customXhr } = useXhr()
    const { aboutVideo, dispatch } = useAboutVideoContext();

    const requiredFields = ['alt', 'title', 'videoLink'];

    const handleOnChange = (e) => {
        const { id, value } = e.target;

        setIsUpdate(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const generateUniqueFileName = (file) => {
        const timestamp = Date.now();
        const randomNumber = Math.floor(Math.random() * 10000);
        const extension = file.name.split('.').pop();

        return `${timestamp}_${randomNumber}.${extension}`;
    };

    const handlePhotoInput = (e) => {
        const file = e.target.files[0];
        const id = e.target.id;

        const newFileName = generateUniqueFileName(file);
        const renamedFile = new File([file], newFileName, { type: file.type });

        setIsUpdate(prevState => ({
            ...prevState,
            [id]: renamedFile
        }));
    };

    const handleSubmit = async () => {
        for (const field of requiredFields) {
            if (!isUpdate[field] || isUpdate[field] === "") {
                return (
                    setError({
                        field: field,
                        note: `Zorunlu alan`
                    }),
                    setIsLoading(false)
                )
            }
        }
        setIsLoading(true);
        const formData = new FormData();
        formData.append('module', 'about_video');
        formData.append('action', 'update');
        formData.append('id', isUpdate.id);
        formData.append('photo', isUpdate.photo);
        formData.append('alt', isUpdate.alt);
        formData.append('title', isUpdate.title);
        formData.append('titleEng', isUpdate.titleEng);
        formData.append('titleFra', isUpdate.titleFra);
        formData.append('titleGer', isUpdate.titleGer);
        formData.append('titleDut', isUpdate.titleDut);
        formData.append('videoLink', isUpdate.videoLink);

        const response = await customXhr(formData);

        if (response.status === "success") {
            setIsUpdate(null);
            setLang(null);
            setStatus(!status);
            setError({
                field: null,
                note: null
            });
            setIsLoading(false);
        };
    };

    const getAboutVideo = async () => {
        const formData = new FormData();
        formData.append('module', 'about_video');
        formData.append('action', 'list');

        const response = await customXhr(formData);

        if (response) {
            dispatch({ type: 'SET_ABOUT_VIDEO', payload: response[0] });
        };
    };

    useEffect(() => {
        getAboutVideo();
    }, [status]);

    return (
        <div className="container-xxl">
            <PageHeader headerTitle="Video Bölümü" />
            <div className="row align-items-center">
                <div className="col-lg-12 col-md-12 flex-column">
                    <div className="row g-3 gy-5 py-3 row-deck">
                        {
                            aboutVideo &&
                            <div className="col-lg-7 col-12">
                                <div className="card customAboutVideo">
                                    <div className="card-body">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="lesson_name">
                                                <div className="project-block">
                                                    <img
                                                        src={process.env.PUBLIC_URL + `/uploads/about_video/${aboutVideo.photoName}`}
                                                        style={{
                                                            width: "90%",
                                                            borderRadius: "8px",
                                                            objectFit: "cover"
                                                        }}
                                                    />
                                                </div>
                                                <h6 className="mb-0 fw-bold  fs-6  mb-2">{aboutVideo.title}</h6>
                                            </div>
                                            <div className="btn-group" role="group" aria-label="Basic outlined example">
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-secondary"
                                                    title="Türkçe Güncelleme"
                                                    onClick={() => { setIsUpdate(aboutVideo); setLang("tur"); }}
                                                >
                                                    <i className="icofont-edit text-success"></i>
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-secondary"
                                                    title="İngilizce Güncelleme"
                                                    onClick={() => { setIsUpdate(aboutVideo); setLang("eng"); }}
                                                >
                                                    <img src={process.env.PUBLIC_URL + `/flags/united-kingdom.png`} style={{ width: "15px" }} />
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-secondary"
                                                    title="Fransızca Güncelleme"
                                                    onClick={() => { setIsUpdate(aboutVideo); setLang("fra"); }}
                                                >
                                                    <img src={process.env.PUBLIC_URL + `/flags/france.png`} style={{ width: "15px" }} />
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-secondary"
                                                    title="Almanca Güncelleme"
                                                    onClick={() => { setIsUpdate(aboutVideo); setLang("ger"); }}
                                                >
                                                    <img src={process.env.PUBLIC_URL + `/flags/germany.png`} style={{ width: "15px" }} />
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-secondary"
                                                    title="Felemenkçe Güncelleme"
                                                    onClick={() => { setIsUpdate(aboutVideo); setLang("dut"); }}
                                                >
                                                    <img src={process.env.PUBLIC_URL + `/flags/netherlands.png`} style={{ width: "15px" }} />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row g-2 pt-4">
                                            <div className="col-12">
                                                <div className="d-flex align-items-center">
                                                    <div className="text-primary">{"Video Link => "}</div>
                                                    <span className="ms-2">{aboutVideo.videoLink}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row g-2 pt-4">
                                            <div className="col-12">
                                                <div className="d-flex align-items-center">
                                                    <div className="text-primary">{"SEO => "}</div>
                                                    <span className="ms-2">{aboutVideo.alt}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Modal
                show={lang === "tur"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Video Bölümünü Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="title" className="form-label">Başlık</label>
                        <input
                            type="text"
                            className="form-control"
                            id="title"
                            value={isUpdate && isUpdate.title}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "title" ? "red" : "var(--border-color)"}` }}
                        />
                    </div>
                    {error.field === "title" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                    <div className="mb-3">
                        <label htmlFor="videoLink" className="form-label">Video Linki</label>
                        <input
                            type="text"
                            className="form-control"
                            id="videoLink"
                            value={isUpdate && isUpdate.videoLink}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "videoLink" ? "red" : "var(--border-color)"}` }}
                        />
                    </div>
                    {error.field === "videoLink" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                    <div className="mb-3">
                        <label htmlFor="alt" className="form-label">
                            {"Seo Kelimeler "}
                            <span className="text-danger">
                                (Lütfen 5 Kelimeden Fazla Yazmayınız!)
                            </span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="alt"
                            value={isUpdate && isUpdate.alt}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "alt" ? "red" : "var(--border-color)"}` }}
                        />
                    </div>
                    {error.field === "alt" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                    <div className="mb-3">
                        <label htmlFor="photo" className="form-label">Fotoğraf Yükle</label>
                        <input
                            className="form-control"
                            type="file"
                            id="photo"
                            onChange={handlePhotoInput}
                        />
                        <div className="form-label" style={{ color: "red" }}>
                            {"Bu alanın geçerli görsel öğe boyutu: 1920x570"}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleSubmit()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "eng"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">İngilizce Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="titleEng" className="form-label">Başlık</label>
                        <input
                            type="text"
                            className="form-control"
                            id="titleEng"
                            value={isUpdate && isUpdate.titleEng}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "titleEng" ? "red" : "var(--border-color)"}` }}
                        />
                    </div>
                    {error.field === "titleEng" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleSubmit()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "fra"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Fransızca Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="titleFra" className="form-label">Başlık</label>
                        <input
                            type="text"
                            className="form-control"
                            id="titleFra"
                            value={isUpdate && isUpdate.titleFra}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "titleFra" ? "red" : "var(--border-color)"}` }}
                        />
                    </div>
                    {error.field === "titleFra" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleSubmit()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "ger"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Almanca Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="titleGer" className="form-label">Başlık</label>
                        <input
                            type="text"
                            className="form-control"
                            id="titleGer"
                            value={isUpdate && isUpdate.titleGer}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "titleGer" ? "red" : "var(--border-color)"}` }}
                        />
                    </div>
                    {error.field === "titleGer" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleSubmit()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "dut"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Felemenkçe Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="titleDut" className="form-label">Başlık</label>
                        <input
                            type="text"
                            className="form-control"
                            id="titleDut"
                            value={isUpdate && isUpdate.titleDut}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "titleDut" ? "red" : "var(--border-color)"}` }}
                        />
                    </div>
                    {error.field === "titleDut" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleSubmit()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AboutVideo;