import { createContext, useReducer } from "react";

export const BakliyatPhotoContext = createContext();

export const bakliyatPhotoReducer = (state, action) => {
    switch (action.type) {
        case 'SET_BAKLIYAT_PHOTO':
            return {
                bakliyatPhoto: action.payload
            }
        case 'CREATE_BAKLIYAT_PHOTO':
            return {
                bakliyatPhoto: [action.payload, ...state.bakliyatPhoto]
            }
        case 'DELETE_BAKLIYAT_PHOTO':
            return {
                bakliyatPhoto: state.bakliyatPhoto.filter(a => a._id !== action.payload._id)
            }
        default:
            return state
    };
};

export const BakliyatPhotoContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(bakliyatPhotoReducer, {
        bakliyatPhoto: null
    });

    return (
        <BakliyatPhotoContext.Provider value={{ ...state, dispatch }}>
            {children}
        </BakliyatPhotoContext.Provider>
    );
};