import { BulgurPhotoContext } from "../context/BulgurPhotoContext";
import { useContext } from "react";

export const useBulgurPhotoContext = () => {
    const context = useContext(BulgurPhotoContext);

    if (!context) {
        return Error('useBulgurPhotoContext must be used inside an BulgurPhotoContextProvider');
    };

    return context;
};