import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import PageHeader from "../../components/common/PageHeader";
import { useXhr } from "hooks/useXhr";
import { useRecipesContext } from "hooks/useRecipesContext";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useBakliyatContext } from "hooks/useBakliyatContext";
import { useBulgurContext } from "hooks/useBulgurContext";
import { usePirincContext } from "hooks/usePirincContext";
import { useRoyalContext } from "hooks/useRoyalContext";
import { useYieldContext } from "hooks/useYieldContext";
import { Button, Spinner } from "react-bootstrap";

function Recipes() {
    //States
    const [item, setItem] = useState({
        photo: null,
        alt: null,
        title: null,
        text1: null,
        text2: null,
        category: null,
        time1: null,
        preparation: null,
        calorie: null,
        titleEng: null,
        textEng1: null,
        textEng2: null,
        timeEng: null,
        preparationEng: null,
        calorieEng: null,
        titleFra: null,
        textFra1: null,
        textFra2: null,
        timeFra: null,
        preparationFra: null,
        calorieFra: null,
        titleGer: null,
        textGer1: null,
        textGer2: null,
        timeGer: null,
        preparationGer: null,
        calorieGer: null,
        titleDut: null,
        textDut1: null,
        textDut2: null,
        timeDut: null,
        preparationDut: null,
        calorieDut: null,
        videoLink: null,
        type1: null,
        productid: null
    });
    const [status, setStatus] = useState(false);
    const [isUpdate, setIsUpdate] = useState(null);
    const [isDelete, setIsDelete] = useState(null);
    const [isModal, setIsModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [lang, setLang] = useState(null);
    const [error, setError] = useState({
        field: null,
        note: null
    });

    //Hooks
    const { customXhr } = useXhr()
    const { recipes, dispatch } = useRecipesContext();
    const { bakliyat, dispatch: bakliyatDispatch } = useBakliyatContext();
    const { bulgur, dispatch: bulgurDispatch } = useBulgurContext();
    const { pirinc, dispatch: pirincDispatch } = usePirincContext();
    const { royal, dispatch: royalDispatch } = useRoyalContext();
    const { yield1, dispatch: yieldDispatch } = useYieldContext();

    const requiredInsertFields = ['alt', 'title', 'text1', 'text2', 'category', 'time1', 'preparation', 'calorie', 'type1', 'productid', 'photo'];
    const requiredUpdateFields = ['alt', 'title', 'text1', 'text2', 'category', 'time1', 'preparation', 'calorie', 'type1', 'productid'];
    const maxItemLimit = 2000;
    
    const handleSelectChange = (e) => {
        const { id, value } = e.target;

        setItem(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleSelectUpdate = (e) => {
        const { id, value } = e.target;

        setIsUpdate(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleTextInput = (e, field) => {
        setItem(prevState => ({
            ...prevState,
            [field]: e
        }));
    };

    const handleTextInputUpdate = (e, field) => {
        setIsUpdate(prevState => ({
            ...prevState,
            [field]: e
        }));
    };

    const handleOnChange = (e) => {
        const { id, value } = e.target;

        setItem(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleOnUpdateChange = (e) => {
        const { id, value } = e.target;

        setIsUpdate(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const generateUniqueFileName = (file) => {
        const timestamp = Date.now();
        const randomNumber = Math.floor(Math.random() * 10000);
        const extension = file.name.split('.').pop();

        return `${timestamp}_${randomNumber}.${extension}`;
    };

    const handlePhotoInput = (e) => {
        const file = e.target.files[0];
        const id = e.target.id;

        const newFileName = generateUniqueFileName(file);

        const newFile = new File([file], newFileName, { type: file.type });

        setItem(prevState => ({
            ...prevState,
            [id]: newFile
        }));
        setError({
            field: null,
            note: null
        });
    };

    const handlePhotoUpdate = (e) => {
        const file = e.target.files[0];
        const id = e.target.id;

        const newFileName = generateUniqueFileName(file);

        const newFile = new File([file], newFileName, { type: file.type });

        setIsUpdate(prevState => ({
            ...prevState,
            [id]: newFile
        }));
    };

    const handleDelete = async (id) => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('module', 'recipes');
        formData.append('action', 'delete');
        formData.append('id', id);

        const response = await customXhr(formData);

        if (response.status === "success") {
            setStatus(!status);
            setIsDelete(null);
            setIsLoading(false);
        };
    };

    const handleUpdate = async () => {
        for (const field of requiredUpdateFields) {
            if (!isUpdate[field] || isUpdate[field] === "") {
                return (
                    setError({
                        field: field,
                        note: `Zorunlu alan`
                    }),
                    setIsLoading(false)
                )
            }
        }
        setIsLoading(true);
        const formData = new FormData();
        formData.append('module', 'recipes');
        formData.append('action', 'update');
        formData.append('id', isUpdate.id);
        formData.append('photo', isUpdate.photo);
        formData.append('alt', isUpdate.alt);
        formData.append('title', isUpdate.title);
        formData.append('text1', isUpdate.text1);
        formData.append('text2', isUpdate.text2);
        formData.append('category', isUpdate.category);
        formData.append('calorie', isUpdate.calorie);
        formData.append('time1', isUpdate.time1);
        formData.append('preparation', isUpdate.preparation);
        formData.append('titleEng', isUpdate.titleEng);
        formData.append('textEng1', isUpdate.textEng1);
        formData.append('textEng2', isUpdate.textEng2);
        formData.append('calorieEng', isUpdate.calorieEng);
        formData.append('timeEng', isUpdate.timeEng);
        formData.append('preparationEng', isUpdate.preparationEng);
        formData.append('titleFra', isUpdate.titleFra);
        formData.append('textFra1', isUpdate.textFra1);
        formData.append('textFra2', isUpdate.textFra2);
        formData.append('calorieFra', isUpdate.calorieFra);
        formData.append('timeFra', isUpdate.timeFra);
        formData.append('preparationFra', isUpdate.preparationFra);
        formData.append('titleGer', isUpdate.titleGer);
        formData.append('textGer1', isUpdate.textGer1);
        formData.append('textGer2', isUpdate.textGer2);
        formData.append('calorieGer', isUpdate.calorieGer);
        formData.append('timeGer', isUpdate.timeGer);
        formData.append('preparationGer', isUpdate.preparationGer);
        formData.append('titleDut', isUpdate.titleDut);
        formData.append('textDut1', isUpdate.textDut1);
        formData.append('textDut2', isUpdate.textDut2);
        formData.append('calorieDut', isUpdate.calorieDut);
        formData.append('timeDut', isUpdate.timeDut);
        formData.append('preparationDut', isUpdate.preparationDut);
        formData.append('videoLink', isUpdate.videoLink);
        formData.append('type1', item.type1);
        formData.append('productid', item.productid);

        const response = await customXhr(formData);

        if (response.status === "success") {
            setIsUpdate(null);
            setLang(null);
            setStatus(!status);
            setError({
                field: null,
                note: null
            });
            setIsLoading(false);
        };
    };

    const handleSubmit = async () => {
        for (const field of requiredInsertFields) {
            if (!item[field] || item[field] === "") {
                return (
                    setError({
                        field: field,
                        note: `Zorunlu alan`
                    }),
                    setIsLoading(false)
                )
            }
        }
        if (recipes.length === maxItemLimit) return (alert("Maksimum veri sınırına ulaşıldı. Lütfen NovusID ekibinden destek talep ediniz."));
        setIsLoading(true);
        const formData = new FormData();
        formData.append('module', 'recipes');
        formData.append('action', 'insert');
        formData.append('photo', item.photo);
        formData.append('alt', item.alt);
        formData.append('title', item.title);
        formData.append('text1', item.text1);
        formData.append('text2', item.text2);
        formData.append('category', item.category);
        formData.append('calorie', item.calorie);
        formData.append('time1', item.time1);
        formData.append('preparation', item.preparation);
        formData.append('titleEng', item.titleEng);
        formData.append('textEng1', item.textEng1);
        formData.append('textEng2', item.textEng2);
        formData.append('calorieEng', item.calorieEng);
        formData.append('timeEng', item.timeEng);
        formData.append('preparationEng', item.preparationEng);
        formData.append('titleFra', item.titleFra);
        formData.append('textFra1', item.textFra1);
        formData.append('textFra2', item.textFra2);
        formData.append('calorieFra', item.calorieFra);
        formData.append('timeFra', item.timeFra);
        formData.append('preparationFra', item.preparationFra);
        formData.append('titleGer', item.titleGer);
        formData.append('textGer1', item.textGer1);
        formData.append('textGer2', item.textGer2);
        formData.append('calorieGer', item.calorieGer);
        formData.append('timeGer', item.timeGer);
        formData.append('preparationGer', item.preparationGer);
        formData.append('titleDut', item.titleDut);
        formData.append('textDut1', item.textDut1);
        formData.append('textDut2', item.textDut2);
        formData.append('calorieDut', item.calorieDut);
        formData.append('timeDut', item.timeDut);
        formData.append('preparationDut', item.preparationDut);
        formData.append('videoLink', item.videoLink);
        formData.append('type1', item.type1);
        formData.append('productid', item.productid);

        const response = await customXhr(formData);

        if (response.status === "success") {
            setItem({
                photo: null,
                alt: null,
                title: null,
                text1: null,
                text2: null,
                category: null,
                time1: null,
                preparation: null,
                calorie: null,
                titleEng: null,
                textEng1: null,
                textEng2: null,
                timeEng: null,
                preparationEng: null,
                calorieEng: null,
                titleFra: null,
                textFra1: null,
                textFra2: null,
                timeFra: null,
                preparationFra: null,
                calorieFra: null,
                titleGer: null,
                textGer1: null,
                textGer2: null,
                timeGer: null,
                preparationGer: null,
                calorieGer: null,
                titleDut: null,
                textDut1: null,
                textDut2: null,
                timeDut: null,
                preparationDut: null,
                calorieDut: null,
                videoLink: null,
                type1: null,
                productid: null
            });
            setIsModal(null);
            setStatus(!status);
            setError({
                field: null,
                note: null
            });
            setIsLoading(false);
        };
    };

    const getRecipes = async () => {
        const formData = new FormData();
        formData.append('module', 'recipes');
        formData.append('action', 'list');

        const response = await customXhr(formData);

        if (response) {
            dispatch({ type: 'SET_RECIPES', payload: response.data });
            getBakliyat();
        };
    };

    const getBakliyat = async () => {
        const formData = new FormData();
        formData.append('module', 'products_bakliyat');
        formData.append('action', 'list');

        const response = await customXhr(formData);

        if (response) {
            bakliyatDispatch({ type: 'SET_BAKLIYAT', payload: response.data });
            getBulgur();
        };
    };

    const getBulgur = async () => {
        const formData = new FormData();
        formData.append('module', 'products_bulgur');
        formData.append('action', 'list');

        const response = await customXhr(formData);

        if (response) {
            bulgurDispatch({ type: 'SET_BULGUR', payload: response.data });
            getPirinc();
        };
    };

    const getPirinc = async () => {
        const formData = new FormData();
        formData.append('module', 'products_pirinc');
        formData.append('action', 'list');

        const response = await customXhr(formData);

        if (response) {
            pirincDispatch({ type: 'SET_PIRINC', payload: response.data });
            getRoyal();
        };
    };

    const getRoyal = async () => {
        const formData = new FormData();
        formData.append('module', 'products_royal');
        formData.append('action', 'list');

        const response = await customXhr(formData);

        if (response) {
            royalDispatch({ type: 'SET_ROYAL', payload: response.data });
            getYield();
        };
    };

    const getYield = async () => {
        const formData = new FormData();
        formData.append('module', 'products_yield');
        formData.append('action', 'list');

        const response = await customXhr(formData);

        if (response) {
            yieldDispatch({ type: 'SET_YIELD', payload: response.data });
        };
    };

    useEffect(() => {
        getRecipes();
    }, [status]);

    const appendThumbToFileName = (fileName) => {
        const dotIndex = fileName.lastIndexOf(".");
        if (dotIndex !== -1) {
            const newFileName = fileName.slice(0, dotIndex) + "_thumb" + fileName.slice(dotIndex);
            return newFileName;
        } else {
            console.log("Dosya uzantısı bulunamadı.");
            return null;
        }
    }

    //Columns
    var columnT = ""

    columnT = [
        {
            name: "BAŞLIK",
            selector: (row) => row.title,
            sortable: true,
            cell: (row) => <a href="#" className="fw-bold text-primary">{row.title}</a>
        },
        {
            name: "KATEGORİ",
            selector: (row) => row.category,
            sortable: true,
            cell: (row) => <div>{categories.map(obj => obj.value === row.category ? obj.label : null).filter(label => label !== null)[0]}</div>
        },
        {
            name: "FOTOĞRAFLAR",
            selector: (row) => row.photoName,
            sortable: true,
            cell: row => <div className="mediaPhotos">
                <img src={process.env.PUBLIC_URL + `/uploads/recipes/${appendThumbToFileName(row.photoName)}`} />
            </div>
        },
        {
            name: "VİDEO LİNK",
            selector: (row) => row.videoLink,
            sortable: true
        },
        {
            name: "GÜNCELLENDİ",
            selector: (row) => row.updatedAt,
            sortable: true
        },
        {
            name: "AKSİYON",
            selector: (row) => { },
            sortable: true,
            cell: (row) => <div className="btn-group" role="group" aria-label="Basic outlined example">
                <button
                    type="button"
                    className="btn btn-outline-secondary"
                    title="Türkçe Güncelleme"
                    onClick={() => { setIsUpdate(row); setLang("tur"); }}
                >
                    <i className="icofont-edit text-success"></i>
                </button>
                <button
                    type="button"
                    className="btn btn-outline-secondary"
                    title="İngilizce Güncelleme"
                    onClick={() => { setIsUpdate(row); setLang("eng"); }}
                >
                    <img src={process.env.PUBLIC_URL + `/flags/united-kingdom.png`} style={{ width: "15px" }} />
                </button>
                <button
                    type="button"
                    className="btn btn-outline-secondary"
                    title="Fransızca Güncelleme"
                    onClick={() => { setIsUpdate(row); setLang("fra"); }}
                >
                    <img src={process.env.PUBLIC_URL + `/flags/france.png`} style={{ width: "15px" }} />
                </button>
                <button
                    type="button"
                    className="btn btn-outline-secondary"
                    title="Almanca Güncelleme"
                    onClick={() => { setIsUpdate(row); setLang("ger"); }}
                >
                    <img src={process.env.PUBLIC_URL + `/flags/germany.png`} style={{ width: "15px" }} />
                </button>
                <button
                    type="button"
                    className="btn btn-outline-secondary"
                    title="Felemenkçe Güncelleme"
                    onClick={() => { setIsUpdate(row); setLang("dut"); }}
                >
                    <img src={process.env.PUBLIC_URL + `/flags/netherlands.png`} style={{ width: "15px" }} />
                </button>
                <button
                    type="button"
                    className="btn btn-outline-secondary deleterow"
                    title="Sil"
                    onClick={() => setIsDelete(row)}
                >
                    <i className="icofont-ui-delete text-danger"></i>
                </button>
            </div>
        }
    ]

    const options = [
        { value: 'bakliyat', label: 'Reis Bakliyat' },
        { value: 'bulgur', label: 'Reis Bulgur' },
        { value: 'pirinc', label: 'Reis Pirinç' },
        { value: 'royal', label: 'Royal' },
        { value: 'yield', label: 'Verim' },
    ];

    const categories = [
        { value: 'corba', label: 'Çorba' },
        { value: 'anayemek', label: 'Ana Yemek' },
        { value: 'yanurun', label: 'Yan Ürün' },
        { value: 'salata', label: 'Salata' },
        { value: 'tatli', label: 'Tatlı' }
    ];

    return (
        <div className="container-xxl">
            <PageHeader headerTitle="Tarifler" renderRight={() => {
                return <div className="col-auto d-flex w-sm-100">
                    <button className="btn btn-dark btn-set-task w-sm-100" onClick={() => { setIsModal(true) }}>
                        <i className="icofont-plus-circle me-2 fs-6"></i>
                        Yeni Tarif
                    </button>
                </div>
            }} />
            <div className="row clearfix g-3">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            {recipes &&
                                <DataTable
                                    title="Tarifler"
                                    columns={columnT}
                                    data={[...recipes].reverse()}
                                    defaultSortField="title"
                                    pagination
                                    selectableRows={false}
                                    className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
                                    highlightOnHover={true}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={isModal}
                onHide={() => {
                    setIsModal(null);
                    setItem({
                        photo: null,
                        alt: null,
                        title: null,
                        text1: null,
                        text2: null,
                        category: null,
                        time1: null,
                        preparation: null,
                        calorie: null,
                        titleEng: null,
                        textEng1: null,
                        textEng2: null,
                        timeEng: null,
                        preparationEng: null,
                        calorieEng: null,
                        titleFra: null,
                        textFra1: null,
                        textFra2: null,
                        timeFra: null,
                        preparationFra: null,
                        calorieFra: null,
                        titleGer: null,
                        textGer1: null,
                        textGer2: null,
                        timeGer: null,
                        preparationGer: null,
                        calorieGer: null,
                        titleDut: null,
                        textDut1: null,
                        textDut2: null,
                        timeDut: null,
                        preparationDut: null,
                        calorieDut: null,
                        videoLink: null,
                        type1: null,
                        productid: null
                    });
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
                dialogClassName="modal-xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Yeni Tarif</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="title" className="form-label">Başlık</label>
                        <input
                            type="text"
                            className="form-control"
                            id="title"
                            value={item && item.title}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "title" ? "red" : "var(--border-color)"}` }}
                        />
                        {error.field === "title" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                    <div className="mb-3">
                        <label htmlFor="text1" className="form-label">
                            {"Malzemeler"}
                        </label>
                        {
                            item &&
                            <ReactQuill
                                theme="snow"
                                id="text1"
                                value={item.text1}
                                onChange={(e) => handleTextInput(e, "text1")}
                                style={{ borderColor: `${error.field === "text1" ? "red" : "var(--border-color)"}` }}
                                modules={{
                                    toolbar: [
                                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                        [{ 'size': [] }],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                        { 'indent': '-1' }, { 'indent': '+1' }],
                                        ['link'],
                                        ['clean'],
                                        [{ 'color': [] }, { 'background': [] }], // Yazı rengi ve arkaplan rengi seçimi
                                    ]
                                }}
                            />
                        }
                        {error.field === "text1" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                    <div className="mb-3">
                        <label htmlFor="text2" className="form-label">
                            {"Yapılışı"}
                        </label>
                        {
                            item &&
                            <ReactQuill
                                theme="snow"
                                id="text2"
                                value={item.text2}
                                onChange={(e) => handleTextInput(e, "text2")}
                                style={{ borderColor: `${error.field === "text2" ? "red" : "var(--border-color)"}` }}
                                modules={{
                                    toolbar: [
                                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                        [{ 'size': [] }],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                        { 'indent': '-1' }, { 'indent': '+1' }],
                                        ['link'],
                                        ['clean'],
                                        [{ 'color': [] }, { 'background': [] }], // Yazı rengi ve arkaplan rengi seçimi
                                    ]
                                }}
                            />
                        }
                        {error.field === "text2" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                    <div className="row g-3 mb-3">
                        <div className="col-sm">
                            <label htmlFor="type1" className="form-label">Marka Seçiniz</label>
                            <select
                                id="type1"
                                className="form-select"
                                onChange={handleSelectChange}
                                style={{ borderColor: `${error.field === "type1" ? "red" : "var(--border-color)"}` }}
                            >
                                <option value="">Seçiniz...</option>
                                {options.map((option) => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                            {error.field === "type1" &&
                                <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                            }
                        </div>
                        <div className="col-sm">
                            <label htmlFor="productid" className="form-label">Ürün Seçiniz</label>
                            <select
                                id="productid"
                                className="form-select"
                                onChange={handleSelectChange}
                                style={{ borderColor: `${error.field === "productid" ? "red" : "var(--border-color)"}` }}
                            >
                                <option value="">Seçiniz...</option>
                                {item.type1 === "bakliyat" &&
                                    bakliyat.map((option) => (
                                        <option key={option.id} value={option.id}>{option.name1}</option>
                                    ))
                                }
                                {item.type1 === "bulgur" &&
                                    bulgur.map((option) => (
                                        <option key={option.id} value={option.id}>{option.name1}</option>
                                    ))
                                }
                                {item.type1 === "pirinc" &&
                                    pirinc.map((option) => (
                                        <option key={option.id} value={option.id}>{option.name1}</option>
                                    ))
                                }
                                {item.type1 === "royal" &&
                                    royal.map((option) => (
                                        <option key={option.id} value={option.id}>{option.name1}</option>
                                    ))
                                }
                                {item.type1 === "yield" &&
                                    yield1.map((option) => (
                                        <option key={option.id} value={option.id}>{option.name1}</option>
                                    ))
                                }
                            </select>
                            {error.field === "productid" &&
                                <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                            }
                        </div>
                    </div>
                    <div className="row g-3 mb-3">
                        <div className="col-sm">
                            <label htmlFor="category" className="form-label">Kategori</label>
                            <select
                                id="category"
                                className="form-select"
                                onChange={handleSelectChange}
                                style={{ borderColor: `${error.field === "category" ? "red" : "var(--border-color)"}` }}
                            >
                                <option value="">Seçiniz...</option>
                                {categories.map((option) => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                            {error.field === "category" &&
                                <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                            }
                        </div>
                        <div className="col-sm">
                            <label htmlFor="calorie" className="form-label">Kalori Miktarı</label>
                            <input
                                type="text"
                                className="form-control"
                                id="calorie"
                                value={item && item.calorie}
                                onChange={handleOnChange}
                            />
                            {error.field === "calorie" &&
                                <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                            }
                        </div>
                    </div>
                    <div className="row g-3 mb-3">
                        <div className="col-sm">
                            <label htmlFor="preparation" className="form-label">Hazırlık Süresi</label>
                            <input
                                type="text"
                                className="form-control"
                                id="preparation"
                                value={item && item.preparation}
                                onChange={handleOnChange}
                            />
                            {error.field === "preparation" &&
                                <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                            }
                        </div>
                        <div className="col-sm">
                            <label htmlFor="time1" className="form-label">Pişirme Süresi</label>
                            <input
                                type="text"
                                className="form-control"
                                id="time1"
                                value={item && item.time1}
                                onChange={handleOnChange}
                            />
                            {error.field === "time1" &&
                                <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                            }
                        </div>
                    </div>
                    <div className="row g-3 mb-3">
                        <div className="col-sm">
                            <label htmlFor="videoLink" className="form-label">Video Link</label>
                            <input
                                type="text"
                                className="form-control"
                                id="videoLink"
                                value={item && item.videoLink}
                                onChange={handleOnChange}
                            />
                            {error.field === "type1" &&
                                <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                            }
                        </div>
                        <div className="col-sm">
                            <label htmlFor="alt" className="form-label">
                                {"Seo Kelimeler "}
                                <span className="text-danger">
                                    (Lütfen 5 Kelimeden Fazla Yazmayınız!)
                                </span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="alt"
                                value={item && item.alt}
                                onChange={handleOnChange}
                                style={{ borderColor: `${error.field === "alt" ? "red" : "var(--border-color)"}` }}
                            />
                            {error.field === "alt" &&
                                <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                            }
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="photo" className="form-label">Fotoğraf Yükle</label>
                        {error.field === "photo" &&
                            <label className="form-label" style={{ color: "red", marginLeft: "5px" }}>{error.note}</label>
                        }
                        <input
                            className="form-control"
                            type="file"
                            id="photo"
                            onChange={handlePhotoInput}
                            style={{ borderColor: `${error.field === "photo" ? "red" : "var(--border-color)"}` }}
                        />
                        <div className="form-label" style={{ color: "red" }}>
                            {"Bu alanın geçerli görsel öğe boyutu: 1075x1075"}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleSubmit()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "tur"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
                dialogClassName="modal-xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Tarifi Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isUpdate &&
                        <>
                            <div className="mb-3">
                                <label htmlFor="title" className="form-label">Başlık</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="title"
                                    value={isUpdate && isUpdate.title}
                                    onChange={handleOnUpdateChange}
                                    style={{ borderColor: `${error.field === "title" ? "red" : "var(--border-color)"}` }}
                                />
                                {error.field === "title" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                            <div className="mb-3">
                                <label htmlFor="text1" className="form-label">
                                    {"Malzemeler"}
                                </label>
                                {
                                    isUpdate &&
                                    <ReactQuill
                                        theme="snow"
                                        id="text1"
                                        rows="10"
                                        value={isUpdate.text1}
                                        onChange={(e) => handleTextInputUpdate(e, "text1")}
                                        style={{ borderColor: `${error.field === "text1" ? "red" : "var(--border-color)"}` }}
                                        modules={{
                                            toolbar: [
                                                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                                [{ size: [] }],
                                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                                { 'indent': '-1' }, { 'indent': '+1' }],
                                                ['link'],
                                                ['clean'],
                                                [{ 'color': [] }, { 'background': [] }], // Yazı rengi ve arkaplan rengi seçimi
                                            ],
                                        }}
                                    />
                                }
                                {error.field === "text1" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                            <div className="mb-3">
                                <label htmlFor="text2" className="form-label">
                                    {"Yapılışı"}
                                </label>
                                {
                                    isUpdate &&
                                    <ReactQuill
                                        theme="snow"
                                        id="text2"
                                        rows="10"
                                        value={isUpdate.text2}
                                        onChange={(e) => handleTextInputUpdate(e, "text2")}
                                        style={{ borderColor: `${error.field === "text2" ? "red" : "var(--border-color)"}` }}
                                        modules={{
                                            toolbar: [
                                                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                                [{ size: [] }],
                                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                                { 'indent': '-1' }, { 'indent': '+1' }],
                                                ['link'],
                                                ['clean'],
                                                [{ 'color': [] }, { 'background': [] }], // Yazı rengi ve arkaplan rengi seçimi
                                            ],
                                        }}
                                    />
                                }
                                {error.field === "text2" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                            <div className="row g-3 mb-3">
                                <div className="col-sm">
                                    <label htmlFor="type1" className="form-label">Marka Seçiniz</label>
                                    <select
                                        id="type1"
                                        className="form-select"
                                        onChange={handleSelectUpdate}
                                        style={{ borderColor: `${error.field === "type1" ? "red" : "var(--border-color)"}` }}
                                    >
                                        {options.map((option) => (
                                            option.value === isUpdate.type1 ?
                                                <option selected key={option.value} value={option.value}>{option.label}</option> :
                                                <option key={option.value} value={option.value}>{option.label}</option>
                                        ))}
                                    </select>
                                    {error.field === "type1" &&
                                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                    }
                                </div>
                                <div className="col-sm">
                                    <label htmlFor="productid" className="form-label">Ürün Seçiniz</label>
                                    <select
                                        id="productid"
                                        className="form-select"
                                        nChange={handleSelectUpdate}
                                        style={{ borderColor: `${error.field === "productid" ? "red" : "var(--border-color)"}` }}
                                    >
                                        {bakliyat && isUpdate.type1 === "bakliyat" &&
                                            bakliyat.map((option) => (
                                                option.value === isUpdate.type1 ?
                                                    <option selected key={option.id} value={option.id}>{option.name1}</option> :
                                                    <option key={option.id} value={option.id}>{option.name1}</option>
                                            ))
                                        }
                                        {bulgur && isUpdate.type1 === "bulgur" &&
                                            bulgur.map((option) => (
                                                option.value === isUpdate.type1 ?
                                                    <option selected key={option.id} value={option.id}>{option.name1}</option> :
                                                    <option key={option.id} value={option.id}>{option.name1}</option>
                                            ))
                                        }
                                        {pirinc && isUpdate.type1 === "pirinc" &&
                                            pirinc.map((option) => (
                                                option.value === isUpdate.type1 ?
                                                    <option selected key={option.id} value={option.id}>{option.name1}</option> :
                                                    <option key={option.id} value={option.id}>{option.name1}</option>
                                            ))
                                        }
                                        {royal && isUpdate.type1 === "royal" &&
                                            royal.map((option) => (
                                                option.value === isUpdate.type1 ?
                                                    <option selected key={option.id} value={option.id}>{option.name1}</option> :
                                                    <option key={option.id} value={option.id}>{option.name1}</option>
                                            ))
                                        }
                                        {yield1 && isUpdate.type1 === "yield" &&
                                            yield1.map((option) => (
                                                option.value === isUpdate.type1 ?
                                                    <option selected key={option.id} value={option.id}>{option.name1}</option> :
                                                    <option key={option.id} value={option.id}>{option.name1}</option>
                                            ))
                                        }
                                    </select>
                                    {error.field === "productid" &&
                                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                    }
                                </div>
                            </div>
                            <div className="row g-3 mb-3">
                                <div className="col-sm">
                                    <label htmlFor="category" className="form-label">Kategori</label>
                                    <select
                                        id="category"
                                        className="form-select"
                                        onChange={handleSelectUpdate}
                                        style={{ borderColor: `${error.field === "category" ? "red" : "var(--border-color)"}` }}
                                    >
                                        <option value="">{isUpdate.category ? isUpdate.category : "Seçiniz..."}</option>
                                        {categories.map((option) => (
                                            <option key={option.value} value={option.value}>{option.label}</option>
                                        ))}
                                    </select>
                                    {error.field === "category" &&
                                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                    }
                                </div>
                                <div className="col-sm">
                                    <label htmlFor="calorie" className="form-label">Kalori Miktarı</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="calorie"
                                        value={isUpdate && isUpdate.calorie}
                                        onChange={handleOnUpdateChange}
                                    />
                                    {error.field === "calorie" &&
                                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                    }
                                </div>
                            </div>
                            <div className="row g-3 mb-3">
                                <div className="col-sm">
                                    <label htmlFor="preparation" className="form-label">Hazırlık Süresi</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="preparation"
                                        value={isUpdate && isUpdate.preparation}
                                        onChange={handleOnUpdateChange}
                                    />
                                    {error.field === "preparation" &&
                                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                    }
                                </div>
                                <div className="col-sm">
                                    <label htmlFor="time1" className="form-label">Pişirme Süresi</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="time1"
                                        value={isUpdate && isUpdate.time1}
                                        onChange={handleOnUpdateChange}
                                    />
                                    {error.field === "time1" &&
                                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                    }
                                </div>
                            </div>
                            <div className="row g-3 mb-3">
                                <div className="col-sm">
                                    <label htmlFor="videoLink" className="form-label">Video Link</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="videoLink"
                                        value={isUpdate && (isUpdate.videoLink === "null" ? "" : isUpdate.videoLink)}
                                        onChange={handleOnUpdateChange}
                                    />
                                </div>
                                <div className="col-sm">
                                    <label htmlFor="alt" className="form-label">
                                        {"Seo Kelimeler "}
                                        <span className="text-danger">
                                            (Lütfen 5 Kelimeden Fazla Yazmayınız!)
                                        </span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="alt"
                                        value={isUpdate && isUpdate.alt}
                                        onChange={handleOnUpdateChange}
                                        style={{ borderColor: `${error.field === "alt" ? "red" : "var(--border-color)"}` }}
                                    />
                                    {error.field === "alt" &&
                                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                    }
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="photo" className="form-label">Fotoğraf Yükle</label>
                                <input
                                    className="form-control"
                                    type="file"
                                    id="photo"
                                    onChange={handlePhotoUpdate}
                                />
                                <div className="form-label" style={{ color: "red" }}>
                                    {"Bu alanın geçerli görsel öğe boyutu: 1075x1075"}
                                </div>
                            </div>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleUpdate()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "eng"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
                dialogClassName="modal-xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">İngilizce Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isUpdate &&
                        <>
                            <div className="mb-3">
                                <label htmlFor="titleEng" className="form-label">Başlık</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="titleEng"
                                    value={isUpdate && isUpdate.titleEng}
                                    onChange={handleOnUpdateChange}
                                    style={{ borderColor: `${error.field === "titleEng" ? "red" : "var(--border-color)"}` }}
                                />
                                {error.field === "titleEng" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                            <div className="mb-3">
                                <label htmlFor="textEng1" className="form-label">
                                    {"Malzemeler"}
                                </label>
                                {
                                    isUpdate &&
                                    <ReactQuill
                                        theme="snow"
                                        id="textEng1"
                                        rows="10"
                                        value={isUpdate.textEng1}
                                        onChange={(e) => handleTextInputUpdate(e, "textEng1")}
                                        style={{ borderColor: `${error.field === "textEng1" ? "red" : "var(--border-color)"}` }}
                                        modules={{
                                            toolbar: [
                                                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                                [{ size: [] }],
                                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                                { 'indent': '-1' }, { 'indent': '+1' }],
                                                ['link'],
                                                ['clean'],
                                                [{ 'color': [] }, { 'background': [] }], // Yazı rengi ve arkaplan rengi seçimi
                                            ],
                                        }}
                                    />
                                }
                                {error.field === "textEng1" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                            <div className="mb-3">
                                <label htmlFor="textEng2" className="form-label">
                                    {"Yapılışı"}
                                </label>
                                {
                                    isUpdate &&
                                    <ReactQuill
                                        theme="snow"
                                        id="textEng2"
                                        rows="10"
                                        value={isUpdate.textEng2}
                                        onChange={(e) => handleTextInputUpdate(e, "textEng2")}
                                        style={{ borderColor: `${error.field === "textEng2" ? "red" : "var(--border-color)"}` }}
                                        modules={{
                                            toolbar: [
                                                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                                [{ size: [] }],
                                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                                { 'indent': '-1' }, { 'indent': '+1' }],
                                                ['link'],
                                                ['clean'],
                                                [{ 'color': [] }, { 'background': [] }], // Yazı rengi ve arkaplan rengi seçimi
                                            ],
                                        }}
                                    />
                                }
                                {error.field === "textEng2" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                            <div className="row g-3 mb-3">
                                <div className="col-sm">
                                    <label htmlFor="calorieEng" className="form-label">Kalori Miktarı</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="calorieEng"
                                        value={isUpdate && isUpdate.calorieEng}
                                        onChange={handleOnUpdateChange}
                                    />
                                    {error.field === "calorieEng" &&
                                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                    }
                                </div>
                                <div className="col-sm">
                                    <label htmlFor="preparationEng" className="form-label">Hazırlık Süresi</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="preparationEng"
                                        value={isUpdate && isUpdate.preparationEng}
                                        onChange={handleOnUpdateChange}
                                    />
                                    {error.field === "preparationEng" &&
                                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                    }
                                </div>
                                <div className="col-sm">
                                    <label htmlFor="timeEng" className="form-label">Pişirme Süresi</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="timeEng"
                                        value={isUpdate && isUpdate.timeEng}
                                        onChange={handleOnUpdateChange}
                                    />
                                    {error.field === "timeEng" &&
                                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                    }
                                </div>
                            </div>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleUpdate()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "fra"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
                dialogClassName="modal-xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Fransızca Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isUpdate &&
                        <>
                            <div className="mb-3">
                                <label htmlFor="titleFra" className="form-label">Başlık</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="titleFra"
                                    value={isUpdate && isUpdate.titleFra}
                                    onChange={handleOnUpdateChange}
                                    style={{ borderColor: `${error.field === "titleFra" ? "red" : "var(--border-color)"}` }}
                                />
                                {error.field === "titleFra" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                            <div className="mb-3">
                                <label htmlFor="textFra1" className="form-label">
                                    {"Malzemeler"}
                                </label>
                                {
                                    isUpdate &&
                                    <ReactQuill
                                        theme="snow"
                                        id="textFra1"
                                        rows="10"
                                        value={isUpdate.textFra1}
                                        onChange={(e) => handleTextInputUpdate(e, "textFra1")}
                                        style={{ borderColor: `${error.field === "textFra1" ? "red" : "var(--border-color)"}` }}
                                        modules={{
                                            toolbar: [
                                                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                                [{ size: [] }],
                                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                                { 'indent': '-1' }, { 'indent': '+1' }],
                                                ['link'],
                                                ['clean'],
                                                [{ 'color': [] }, { 'background': [] }], // Yazı rengi ve arkaplan rengi seçimi
                                            ],
                                        }}
                                    />
                                }
                                {error.field === "textFra1" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                            <div className="mb-3">
                                <label htmlFor="textFra2" className="form-label">
                                    {"Yapılışı"}
                                </label>
                                {
                                    isUpdate &&
                                    <ReactQuill
                                        theme="snow"
                                        id="textFra2"
                                        rows="10"
                                        value={isUpdate.textFra2}
                                        onChange={(e) => handleTextInputUpdate(e, "textFra2")}
                                        style={{ borderColor: `${error.field === "textFra2" ? "red" : "var(--border-color)"}` }}
                                        modules={{
                                            toolbar: [
                                                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                                [{ size: [] }],
                                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                                { 'indent': '-1' }, { 'indent': '+1' }],
                                                ['link'],
                                                ['clean'],
                                                [{ 'color': [] }, { 'background': [] }], // Yazı rengi ve arkaplan rengi seçimi
                                            ],
                                        }}
                                    />
                                }
                                {error.field === "textFra2" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                            <div className="row g-3 mb-3">
                                <div className="col-sm">
                                    <label htmlFor="calorieFra" className="form-label">Kalori Miktarı</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="calorieFra"
                                        value={isUpdate && isUpdate.calorieFra}
                                        onChange={handleOnUpdateChange}
                                    />
                                    {error.field === "calorieFra" &&
                                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                    }
                                </div>
                                <div className="col-sm">
                                    <label htmlFor="preparationFra" className="form-label">Hazırlık Süresi</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="preparationFra"
                                        value={isUpdate && isUpdate.preparationFra}
                                        onChange={handleOnUpdateChange}
                                    />
                                    {error.field === "preparationFra" &&
                                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                    }
                                </div>
                                <div className="col-sm">
                                    <label htmlFor="timeFra" className="form-label">Pişirme Süresi</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="timeFra"
                                        value={isUpdate && isUpdate.timeFra}
                                        onChange={handleOnUpdateChange}
                                    />
                                    {error.field === "timeFra" &&
                                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                    }
                                </div>
                            </div>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleUpdate()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "ger"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
                dialogClassName="modal-xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Almanca Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isUpdate &&
                        <>
                            <div className="mb-3">
                                <label htmlFor="titleGer" className="form-label">Başlık</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="titleGer"
                                    value={isUpdate && isUpdate.titleGer}
                                    onChange={handleOnUpdateChange}
                                    style={{ borderColor: `${error.field === "titleGer" ? "red" : "var(--border-color)"}` }}
                                />
                                {error.field === "titleGer" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                            <div className="mb-3">
                                <label htmlFor="textGer1" className="form-label">
                                    {"Malzemeler"}
                                </label>
                                {
                                    isUpdate &&
                                    <ReactQuill
                                        theme="snow"
                                        id="textGer1"
                                        rows="10"
                                        value={isUpdate.textGer1}
                                        onChange={(e) => handleTextInputUpdate(e, "textGer1")}
                                        style={{ borderColor: `${error.field === "textGer1" ? "red" : "var(--border-color)"}` }}
                                        modules={{
                                            toolbar: [
                                                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                                [{ size: [] }],
                                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                                { 'indent': '-1' }, { 'indent': '+1' }],
                                                ['link'],
                                                ['clean'],
                                                [{ 'color': [] }, { 'background': [] }], // Yazı rengi ve arkaplan rengi seçimi
                                            ],
                                        }}
                                    />
                                }
                                {error.field === "textGer1" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                            <div className="mb-3">
                                <label htmlFor="textGer2" className="form-label">
                                    {"Yapılışı"}
                                </label>
                                {
                                    isUpdate &&
                                    <ReactQuill
                                        theme="snow"
                                        id="textGer2"
                                        rows="10"
                                        value={isUpdate.textGer2}
                                        onChange={(e) => handleTextInputUpdate(e, "textGer2")}
                                        style={{ borderColor: `${error.field === "textGer2" ? "red" : "var(--border-color)"}` }}
                                        modules={{
                                            toolbar: [
                                                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                                [{ size: [] }],
                                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                                { 'indent': '-1' }, { 'indent': '+1' }],
                                                ['link'],
                                                ['clean'],
                                                [{ 'color': [] }, { 'background': [] }], // Yazı rengi ve arkaplan rengi seçimi
                                            ],
                                        }}
                                    />
                                }
                                {error.field === "textGer2" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                            <div className="row g-3 mb-3">
                                <div className="col-sm">
                                    <label htmlFor="calorieGer" className="form-label">Kalori Miktarı</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="calorieGer"
                                        value={isUpdate && isUpdate.calorieGer}
                                        onChange={handleOnUpdateChange}
                                    />
                                    {error.field === "calorieGer" &&
                                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                    }
                                </div>
                                <div className="col-sm">
                                    <label htmlFor="preparationGer" className="form-label">Hazırlık Süresi</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="preparationGer"
                                        value={isUpdate && isUpdate.preparationGer}
                                        onChange={handleOnUpdateChange}
                                    />
                                    {error.field === "preparationGer" &&
                                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                    }
                                </div>
                                <div className="col-sm">
                                    <label htmlFor="timeGer" className="form-label">Pişirme Süresi</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="timeGer"
                                        value={isUpdate && isUpdate.timeGer}
                                        onChange={handleOnUpdateChange}
                                    />
                                    {error.field === "timeGer" &&
                                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                    }
                                </div>
                            </div>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleUpdate()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "dut"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
                dialogClassName="modal-xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Felemenkçe Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isUpdate &&
                        <>
                            <div className="mb-3">
                                <label htmlFor="titleDut" className="form-label">Başlık</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="titleDut"
                                    value={isUpdate && isUpdate.titleDut}
                                    onChange={handleOnUpdateChange}
                                    style={{ borderColor: `${error.field === "titleDut" ? "red" : "var(--border-color)"}` }}
                                />
                                {error.field === "titleDut" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                            <div className="mb-3">
                                <label htmlFor="textDut1" className="form-label">
                                    {"Malzemeler"}
                                </label>
                                {
                                    isUpdate &&
                                    <ReactQuill
                                        theme="snow"
                                        id="textDut1"
                                        rows="10"
                                        value={isUpdate.textDut1}
                                        onChange={(e) => handleTextInputUpdate(e, "textDut1")}
                                        style={{ borderColor: `${error.field === "textDut1" ? "red" : "var(--border-color)"}` }}
                                        modules={{
                                            toolbar: [
                                                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                                [{ size: [] }],
                                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                                { 'indent': '-1' }, { 'indent': '+1' }],
                                                ['link'],
                                                ['clean'],
                                                [{ 'color': [] }, { 'background': [] }], // Yazı rengi ve arkaplan rengi seçimi
                                            ],
                                        }}
                                    />
                                }
                                {error.field === "textDut1" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                            <div className="mb-3">
                                <label htmlFor="textDut2" className="form-label">
                                    {"Yapılışı"}
                                </label>
                                {
                                    isUpdate &&
                                    <ReactQuill
                                        theme="snow"
                                        id="textDut2"
                                        rows="10"
                                        value={isUpdate.textDut2}
                                        onChange={(e) => handleTextInputUpdate(e, "textDut2")}
                                        style={{ borderColor: `${error.field === "textDut2" ? "red" : "var(--border-color)"}` }}
                                        modules={{
                                            toolbar: [
                                                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                                [{ size: [] }],
                                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                                { 'indent': '-1' }, { 'indent': '+1' }],
                                                ['link'],
                                                ['clean'],
                                                [{ 'color': [] }, { 'background': [] }], // Yazı rengi ve arkaplan rengi seçimi
                                            ],
                                        }}
                                    />
                                }
                                {error.field === "textDut2" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                            <div className="row g-3 mb-3">
                                <div className="col-sm">
                                    <label htmlFor="calorieDut" className="form-label">Kalori Miktarı</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="calorieDut"
                                        value={isUpdate && isUpdate.calorieDut}
                                        onChange={handleOnUpdateChange}
                                    />
                                    {error.field === "calorieDut" &&
                                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                    }
                                </div>
                                <div className="col-sm">
                                    <label htmlFor="preparationDut" className="form-label">Hazırlık Süresi</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="preparationDut"
                                        value={isUpdate && isUpdate.preparationDut}
                                        onChange={handleOnUpdateChange}
                                    />
                                    {error.field === "preparationDut" &&
                                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                    }
                                </div>
                                <div className="col-sm">
                                    <label htmlFor="timeDut" className="form-label">Pişirme Süresi</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="timeDut"
                                        value={isUpdate && isUpdate.timeDut}
                                        onChange={handleOnUpdateChange}
                                    />
                                    {error.field === "timeDut" &&
                                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                    }
                                </div>
                            </div>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleUpdate()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                centered
                show={isDelete}
                onHide={() => {
                    setIsDelete(null);
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Uyarı</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <div className="form-label">
                            Bu tarifi silmek istediğinize emin misiniz?
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="danger"
                        className="me-1 text-white"
                        onClick={() => handleDelete(isDelete.id)}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Siliniyor...
                                </> :
                                <>
                                    <i className="icofont-ui-delete"></i>
                                    &ensp;
                                    Sil
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Recipes;