import { createContext, useReducer } from "react";

export const RoyalPhotoContext = createContext();

export const royalPhotoReducer = (state, action) => {
    switch (action.type) {
        case 'SET_ROYAL_PHOTO':
            return {
                royalPhoto: action.payload
            }
        case 'CREATE_ROYAL_PHOTO':
            return {
                royalPhoto: [action.payload, ...state.royalPhoto]
            }
        case 'DELETE_ROYAL_PHOTO':
            return {
                royalPhoto: state.royalPhoto.filter(a => a._id !== action.payload._id)
            }
        default:
            return state
    };
};

export const RoyalPhotoContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(royalPhotoReducer, {
        royalPhoto: null
    });

    return (
        <RoyalPhotoContext.Provider value={{ ...state, dispatch }}>
            {children}
        </RoyalPhotoContext.Provider>
    );
};