import React from "react";

function Slider(props) {

    const {
        id,
        photoName,
        alt,
        title,
        firstButtonText,
        secondButtonText,
        active,
        onClickEdit,
        onClickEditEng,
        onClickEditFra,
        onClickEditGer,
        onClickEditDut,
    } = props;

    return (
        <div className="card customSliders" style={{ opacity: (active === "false" ? "0.5" : "1") }}>
            <div className="card-body">
                <div className="d-flex align-items-center justify-content-between mt-5">
                    <div className="lesson_name">
                        <div className="project-block" style={{ width: "100%", height: "100%" }}>
                            {id === "1" || id === "2" || id === "3" || id === "4" || id === "5" || id === "6" || id === "7" || id === "8" || id === "9" ?
                                <img
                                    src={process.env.PUBLIC_URL + `/uploads/sliders/${photoName}`}
                                    style={{
                                        width: "90%",
                                        borderRadius: "8px",
                                        objectFit: "cover"
                                    }}
                                /> :
                                <img
                                    src={process.env.PUBLIC_URL + `/uploads/sliders/${photoName}`}
                                    style={{
                                        width: "35%",
                                        borderRadius: "8px",
                                        objectFit: "cover"
                                    }}
                                />
                            }
                        </div>
                        <h6 className="mb-0 fw-bold  fs-6  mb-2">{title}</h6>
                        <div className="g-2 pt-4">
                            <div className="d-flex align-items-center">
                                <div className="text-primary">
                                    {"1.Buton =>"}
                                    <span className="ms-2 text-black">{firstButtonText}</span>
                                </div>
                            </div>
                        </div>
                        <div className="g-2 pt-4">
                            <div className="d-flex align-items-center">
                                <div className="text-primary">
                                    {"2.Buton =>"}
                                    <span className="ms-2 text-black">{secondButtonText}</span>
                                </div>
                            </div>
                        </div>
                        <div className="row g-2 pt-4">
                            <div className="col-12">
                                <div className="d-flex align-items-center">
                                    <div className="text-primary">{"SEO => "}</div>
                                    <span className="ms-2">{alt}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="btn-group-vertical" role="group" aria-label="Basic outlined example">
                        <button type="button" className="btn btn-outline-secondary" onClick={onClickEdit}>
                            <i className="icofont-edit text-success"></i>
                        </button>
                        <button
                            type="button"
                            className="btn btn-outline-secondary"
                            title="İngilizce Güncelleme"
                            onClick={onClickEditEng}
                        >
                            <img src={process.env.PUBLIC_URL + `/flags/united-kingdom.png`} style={{ width: "15px" }} />
                        </button>
                        <button
                            type="button"
                            className="btn btn-outline-secondary"
                            title="Fransızca Güncelleme"
                            onClick={onClickEditFra}
                        >
                            <img src={process.env.PUBLIC_URL + `/flags/france.png`} style={{ width: "15px" }} />
                        </button>
                        <button
                            type="button"
                            className="btn btn-outline-secondary"
                            title="Almanca Güncelleme"
                            onClick={onClickEditGer}
                        >
                            <img src={process.env.PUBLIC_URL + `/flags/germany.png`} style={{ width: "15px" }} />
                        </button>
                        <button
                            type="button"
                            className="btn btn-outline-secondary"
                            title="Felemenkçe Güncelleme"
                            onClick={onClickEditDut}
                        >
                            <img src={process.env.PUBLIC_URL + `/flags/netherlands.png`} style={{ width: "15px" }} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Slider;