import React from "react";
import { Route, Routes } from "react-router-dom";
import Header from "../components/common/Header";

//New Pages
import Users from "components/Auth/Users";
import SocialMedia from "pages/common/SocialMedia";
import Foundation from "pages/homepage/foundation/Foundation";
import AboutUs from "pages/about/AboutUs";
import AboutVideo from "pages/about/AboutVideo";
import Headers from "pages/common/Headers";
import TimelineList from "pages/about/TimelineList";
import Principles from "pages/about/Principles";
import Media from "pages/media/Media";
import Bakliyat from "pages/products/Bakliyat";
import Bulgur from "pages/products/Bulgur";
import Pirinc from "pages/products/Pirinc";
import Royal from "pages/products/Royal";
import Yield from "pages/products/Yield";
import Contact from "pages/contact/Contact";
import SocialResponsibility from "pages/social/SocialResponsibility";
import HealthyLife from "pages/health/HealthyLife";
import SustainabilityText from "pages/sustainability/SustainabilityText";
import SustainabilityPhotos from "pages/sustainability/SustainabilityPhotos";
import SustainabilityButtons from "pages/sustainability/SustainabilityButtons";
import Recipes from "pages/recipes/Recipes";
import WhyText from "pages/why/WhyText";
import WhyPhotos from "pages/why/WhyPhotos";
import CuriousThings from "pages/why/CuriousThings";
import Innerpages from "pages/innerpages/Innerpages";
import Awards from "pages/awards/Awards";
import Quality from "pages/quality/Quality";
import BigSliders from "pages/homepage/sliders/BigSliders";
import SmallSliders from "pages/homepage/sliders/SmallSliders";
import Kvkk from "pages/security/kvkk";
import Privacy from "pages/security/privacy";
import Cookie from "pages/security/cookie";

function MainIndex(props) {

    return (
        <div className="main px-lg-4 px-md-4">
            <Header />
            <div className="body d-flex py-lg-3 py-md-2">
                <Routes>
                    <Route exact path={`/`} element={<BigSliders />} />
                    <Route path={`/users`} element={<Users />} />
                    <Route path={`/social-media`} element={<SocialMedia />} />
                    <Route path={`/big-sliders`} element={<BigSliders />} />
                    <Route path={`/small-sliders`} element={<SmallSliders />} />
                    <Route path={`/foundation`} element={<Foundation />} />
                    <Route path={`/headers`} element={<Headers />} />
                    <Route path={`/about-us`} element={<AboutUs />} />
                    <Route path={`/about-video`} element={<AboutVideo />} />
                    <Route path={`/timeline`} element={<TimelineList />} />
                    <Route path={`/principles`} element={<Principles />} />
                    <Route path={`/media`} element={<Media />} />
                    <Route path={`/bakliyat`} element={<Bakliyat />} />
                    <Route path={`/bulgur`} element={<Bulgur />} />
                    <Route path={`/pirinc`} element={<Pirinc />} />
                    <Route path={`/royal`} element={<Royal />} />
                    <Route path={`/yield`} element={<Yield />} />
                    <Route path={`/contact`} element={<Contact />} />
                    <Route path={`/social-responsibility`} element={<SocialResponsibility />} />
                    <Route path={`/healthy-life`} element={<HealthyLife />} />
                    <Route path={`/sustainability-text`} element={<SustainabilityText />} />
                    <Route path={`/sustainability-photos`} element={<SustainabilityPhotos />} />
                    <Route path={`/sustainability-buttons`} element={<SustainabilityButtons />} />
                    <Route path={`/recipes`} element={<Recipes />} />
                    <Route path={`/why-text`} element={<WhyText />} />
                    <Route path={`/why-photos`} element={<WhyPhotos />} />
                    <Route path={`/curious-things`} element={<CuriousThings />} />
                    <Route path={`/innerpages`} element={<Innerpages />} />
                    <Route path={`/awards`} element={<Awards />} />
                    <Route path={`/quality`} element={<Quality />} />
                    <Route path={`/kvkk`} element={<Kvkk />} />
                    <Route path={`/privacy`} element={<Privacy />} />
                    <Route path={`/cookie`} element={<Cookie />} />
                </Routes>
            </div>
        </div>
    )
}


export default MainIndex;