import { BakliyatPhotoContext } from "../context/BakliyatPhotoContext";
import { useContext } from "react";

export const useBakliyatPhotoContext = () => {
    const context = useContext(BakliyatPhotoContext);

    if (!context) {
        return Error('useBakliyatPhotoContext must be used inside an BakliyatPhotoContextProvider');
    };

    return context;
};