import { RoyalPhotoContext } from "../context/RoyalPhotoContext";
import { useContext } from "react";

export const useRoyalPhotoContext = () => {
    const context = useContext(RoyalPhotoContext);

    if (!context) {
        return Error('useRoyalPhotoContext must be used inside an RoyalPhotoContextProvider');
    };

    return context;
};