import { HealthyLifeContext } from "../context/HealthyLifeContext";
import { useContext } from "react";

export const useHealthyLifeContext = () => {
    const context = useContext(HealthyLifeContext);

    if (!context) {
        return Error('useHealthyLifeContext must be used inside an HealthyLifeContextProvider');
    };

    return context;
};