import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import PageHeader from "../../components/common/PageHeader";
import { useXhr } from "hooks/useXhr";
import { useAboutUsContext } from "hooks/useAboutUsContext";
import { Button, Spinner } from "react-bootstrap";

function AboutUs() {
    //States
    const [status, setStatus] = useState(false);
    const [isUpdate, setIsUpdate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [lang, setLang] = useState(null);
    const [error, setError] = useState({
        field: null,
        note: null
    });

    //Hooks
    const { customXhr } = useXhr()
    const { aboutUs, dispatch } = useAboutUsContext();

    const requiredFields = ['alt', 'title', 'text1', 'text2'];

    const handleOnChange = (e) => {
        const { id, value } = e.target;

        setIsUpdate(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const generateUniqueFileName = (file) => {
        const timestamp = Date.now();
        const randomNumber = Math.floor(Math.random() * 10000);
        const extension = file.name.split('.').pop();

        return `${timestamp}_${randomNumber}.${extension}`;
    };

    const handlePhotoInput = (e) => {
        const file = e.target.files[0];
        const id = e.target.id;

        const newFileName = generateUniqueFileName(file);
        const renamedFile = new File([file], newFileName, { type: file.type });

        setIsUpdate(prevState => ({
            ...prevState,
            [id]: renamedFile
        }));
    };

    const handleSubmit = async () => {
        for (const field of requiredFields) {
            if (!isUpdate[field] || isUpdate[field] === "") {
                return (
                    setError({
                        field: field,
                        note: `Zorunlu alan`
                    }),
                    setIsLoading(false)
                )
            }
        }
        setIsLoading(true);
        const formData = new FormData();
        formData.append('module', 'about_us');
        formData.append('action', 'update');
        formData.append('id', isUpdate.id);
        formData.append('photo', isUpdate.photo);
        formData.append('alt', isUpdate.alt);
        formData.append('title', isUpdate.title);
        formData.append('text1', isUpdate.text1);
        formData.append('text2', isUpdate.text2);
        formData.append('titleEng', isUpdate.titleEng);
        formData.append('textEng1', isUpdate.textEng1);
        formData.append('textEng2', isUpdate.textEng2);
        formData.append('titleFra', isUpdate.titleFra);
        formData.append('textFra1', isUpdate.textFra1);
        formData.append('textFra2', isUpdate.textFra2);
        formData.append('titleGer', isUpdate.titleGer);
        formData.append('textGer1', isUpdate.textGer1);
        formData.append('textGer2', isUpdate.textGer2);
        formData.append('titleDut', isUpdate.titleDut);
        formData.append('textDut1', isUpdate.textDut1);
        formData.append('textDut2', isUpdate.textDut2);
        formData.append('buttonText', isUpdate.buttonText);
        formData.append('buttonLink', isUpdate.buttonLink);

        const response = await customXhr(formData);

        if (response.status === "success") {
            setIsUpdate(null);
            setLang(null);
            setStatus(!status);
            setError({
                field: null,
                note: null
            });
            setIsLoading(false);
        };
    };

    const getAboutUs = async () => {
        const formData = new FormData();
        formData.append('module', 'about_us');
        formData.append('action', 'list');

        const response = await customXhr(formData);

        if (response) {
            dispatch({ type: 'SET_ABOUT_US', payload: response[0] });
        };
    };

    useEffect(() => {
        getAboutUs();
    }, [status]);

    return (
        <div className="container-xxl">
            <PageHeader headerTitle="Hakkımızda" />
            <div className="row align-items-center">
                <div className="col-lg-12 col-md-12 flex-column">
                    {aboutUs &&
                        <div className="row g-3 gy-5 py-3 row-deck">
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="card d-flex justify-content-center align-items-center">
                                    <img src={process.env.PUBLIC_URL + `/uploads/about_us/${aboutUs.photoName}`} style={{
                                        width: "100%",
                                        borderRadius: "8px",
                                        objectFit: "cover"
                                    }} />
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-6 col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex align-items-center justify-content-between mt-1">
                                            <div className="lesson_name">
                                                <h6 className="mb-0 fw-bold  fs-6  mb-2">{aboutUs.title}</h6>
                                            </div>
                                            <div className="btn-group" role="group" aria-label="Basic outlined example">
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-secondary"
                                                    title="Türkçe Güncelleme"
                                                    onClick={() => { setIsUpdate(aboutUs); setLang("tur"); }}
                                                >
                                                    <i className="icofont-edit text-success"></i>
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-secondary"
                                                    title="İngilizce Güncelleme"
                                                    onClick={() => { setIsUpdate(aboutUs); setLang("eng"); }}
                                                >
                                                    <img src={process.env.PUBLIC_URL + `/flags/united-kingdom.png`} style={{ width: "15px" }} />
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-secondary"
                                                    title="Fransızca Güncelleme"
                                                    onClick={() => { setIsUpdate(aboutUs); setLang("fra"); }}
                                                >
                                                    <img src={process.env.PUBLIC_URL + `/flags/france.png`} style={{ width: "15px" }} />
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-secondary"
                                                    title="Almanca Güncelleme"
                                                    onClick={() => { setIsUpdate(aboutUs); setLang("ger"); }}
                                                >
                                                    <img src={process.env.PUBLIC_URL + `/flags/germany.png`} style={{ width: "15px" }} />
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-secondary"
                                                    title="Felemenkçe Güncelleme"
                                                    onClick={() => { setIsUpdate(aboutUs); setLang("dut"); }}
                                                >
                                                    <img src={process.env.PUBLIC_URL + `/flags/netherlands.png`} style={{ width: "15px" }} />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row g-2 pt-4">
                                            <div className="d-flex align-items-center">
                                                <span className="ms-2">{aboutUs.text1}</span>
                                            </div>
                                        </div>
                                        <div className="row g-2 pt-4">
                                            <div className="d-flex align-items-center">
                                                <span className="ms-2">{aboutUs.text2}</span>
                                            </div>
                                        </div>
                                        <div className="row g-2 pt-4">
                                            <div className="col-12">
                                                <div className="d-flex align-items-center">
                                                    <div className="text-primary">{"SEO => "}</div>
                                                    <span className="ms-2">{aboutUs.alt}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <Modal
                show={lang === "tur"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Hakkımızda Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="title" className="form-label">Başlık</label>
                        <input
                            type="text"
                            className="form-control"
                            id="title"
                            value={isUpdate && isUpdate.title}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "title" ? "red" : "var(--border-color)"}` }}
                        />
                    </div>
                    {error.field === "title" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                    <div className="mb-3">
                        <label htmlFor="text1" className="form-label">Yazı 1</label>
                        <textarea
                            className="form-control"
                            id="text1"
                            rows="5"
                            value={isUpdate && isUpdate.text1}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "text1" ? "red" : "var(--border-color)"}` }}
                        ></textarea>
                    </div>
                    {error.field === "text1" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                    <div className="mb-3">
                        <label htmlFor="text2" className="form-label">Yazı 2</label>
                        <textarea
                            className="form-control"
                            id="text2"
                            rows="5"
                            value={isUpdate && isUpdate.text2}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "text2" ? "red" : "var(--border-color)"}` }}
                        ></textarea>
                    </div>
                    {error.field === "text2" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                    <div className="mb-3">
                        <label htmlFor="alt" className="form-label">
                            {"Seo Kelimeler "}
                            <span className="text-danger">
                                (Lütfen 5 Kelimeden Fazla Yazmayınız!)
                            </span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="alt"
                            value={isUpdate && isUpdate.alt}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "alt" ? "red" : "var(--border-color)"}` }}
                        />
                    </div>
                    {error.field === "alt" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                    <div className="mb-3">
                        <label htmlFor="photo" className="form-label">Fotoğraf Yükle</label>
                        <input
                            className="form-control"
                            type="file"
                            id="photo"
                            onChange={handlePhotoInput}
                        />
                        <div className="form-label" style={{ color: "red" }}>
                            {"Bu alanın geçerli görsel öğe boyutu: 510x650"}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleSubmit()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "eng"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">İngilizce Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="titleEng" className="form-label">Başlık</label>
                        <input
                            type="text"
                            className="form-control"
                            id="titleEng"
                            value={isUpdate && isUpdate.titleEng}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "titleEng" ? "red" : "var(--border-color)"}` }}
                        />
                    </div>
                    {error.field === "titleEng" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                    <div className="mb-3">
                        <label htmlFor="textEng1" className="form-label">Yazı 1</label>
                        <textarea
                            className="form-control"
                            id="textEng1"
                            rows="5"
                            value={isUpdate && isUpdate.textEng1}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "textEng1" ? "red" : "var(--border-color)"}` }}
                        ></textarea>
                    </div>
                    {error.field === "textEng1" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                    <div className="mb-3">
                        <label htmlFor="textEng2" className="form-label">Yazı 2</label>
                        <textarea
                            className="form-control"
                            id="textEng2"
                            rows="5"
                            value={isUpdate && isUpdate.textEng2}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "textEng2" ? "red" : "var(--border-color)"}` }}
                        ></textarea>
                    </div>
                    {error.field === "textEng2" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleSubmit()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "fra"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Fransızca Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="titleFra" className="form-label">Başlık</label>
                        <input
                            type="text"
                            className="form-control"
                            id="titleFra"
                            value={isUpdate && isUpdate.titleFra}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "titleFra" ? "red" : "var(--border-color)"}` }}
                        />
                    </div>
                    {error.field === "titleFra" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                    <div className="mb-3">
                        <label htmlFor="textFra1" className="form-label">Yazı 1</label>
                        <textarea
                            className="form-control"
                            id="textFra1"
                            rows="5"
                            value={isUpdate && isUpdate.textFra1}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "textFra1" ? "red" : "var(--border-color)"}` }}
                        ></textarea>
                    </div>
                    {error.field === "textFra1" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                    <div className="mb-3">
                        <label htmlFor="textFra2" className="form-label">Yazı 2</label>
                        <textarea
                            className="form-control"
                            id="textFra2"
                            rows="5"
                            value={isUpdate && isUpdate.textFra2}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "textFra2" ? "red" : "var(--border-color)"}` }}
                        ></textarea>
                    </div>
                    {error.field === "textFra2" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleSubmit()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "ger"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Almanca Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="titleGer" className="form-label">Başlık</label>
                        <input
                            type="text"
                            className="form-control"
                            id="titleGer"
                            value={isUpdate && isUpdate.titleGer}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "titleGer" ? "red" : "var(--border-color)"}` }}
                        />
                    </div>
                    {error.field === "titleGer" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                    <div className="mb-3">
                        <label htmlFor="textGer1" className="form-label">Yazı 1</label>
                        <textarea
                            className="form-control"
                            id="textGer1"
                            rows="5"
                            value={isUpdate && isUpdate.textGer1}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "textGer1" ? "red" : "var(--border-color)"}` }}
                        ></textarea>
                    </div>
                    {error.field === "textGer1" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                    <div className="mb-3">
                        <label htmlFor="textGer2" className="form-label">Yazı 2</label>
                        <textarea
                            className="form-control"
                            id="textGer2"
                            rows="5"
                            value={isUpdate && isUpdate.textGer2}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "textGer2" ? "red" : "var(--border-color)"}` }}
                        ></textarea>
                    </div>
                    {error.field === "textGer2" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleSubmit()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "dut"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Felemenkçe Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="titleDut" className="form-label">Başlık</label>
                        <input
                            type="text"
                            className="form-control"
                            id="titleDut"
                            value={isUpdate && isUpdate.titleDut}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "titleDut" ? "red" : "var(--border-color)"}` }}
                        />
                    </div>
                    {error.field === "titleDut" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                    <div className="mb-3">
                        <label htmlFor="textDut1" className="form-label">Yazı 1</label>
                        <textarea
                            className="form-control"
                            id="textDut1"
                            rows="5"
                            value={isUpdate && isUpdate.textDut1}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "textDut1" ? "red" : "var(--border-color)"}` }}
                        ></textarea>
                    </div>
                    {error.field === "textDut1" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                    <div className="mb-3">
                        <label htmlFor="textDut2" className="form-label">Yazı 2</label>
                        <textarea
                            className="form-control"
                            id="textDut2"
                            rows="5"
                            value={isUpdate && isUpdate.textDut2}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "textDut2" ? "red" : "var(--border-color)"}` }}
                        ></textarea>
                    </div>
                    {error.field === "textDut2" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleSubmit()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AboutUs;