import { createContext, useReducer } from "react";

export const YieldPhotoContext = createContext();

export const yieldPhotoReducer = (state, action) => {
    switch (action.type) {
        case 'SET_YIELD_PHOTO':
            return {
                yieldPhoto: action.payload
            }
        case 'CREATE_YIELD_PHOTO':
            return {
                yieldPhoto: [action.payload, ...state.yieldPhoto]
            }
        case 'DELETE_YIELD_PHOTO':
            return {
                yieldPhoto: state.yieldPhoto.filter(a => a._id !== action.payload._id)
            }
        default:
            return state
    };
};

export const YieldPhotoContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(yieldPhotoReducer, {
        yieldPhoto: null
    });

    return (
        <YieldPhotoContext.Provider value={{ ...state, dispatch }}>
            {children}
        </YieldPhotoContext.Provider>
    );
};