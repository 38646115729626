import { PirincPhotoContext } from "../context/PirincPhotoContext";
import { useContext } from "react";

export const usePirincPhotoContext = () => {
    const context = useContext(PirincPhotoContext);

    if (!context) {
        return Error('usePirincPhotoContext must be used inside an PirincPhotoContextProvider');
    };

    return context;
};