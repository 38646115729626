import { createContext, useReducer } from "react";

export const PirincPhotoContext = createContext();

export const pirincPhotoReducer = (state, action) => {
    switch (action.type) {
        case 'SET_PIRINC_PHOTO':
            return {
                pirincPhoto: action.payload
            }
        case 'CREATE_PIRINC_PHOTO':
            return {
                pirincPhoto: [action.payload, ...state.pirincPhoto]
            }
        case 'DELETE_PIRINC_PHOTO':
            return {
                pirincPhoto: state.pirincPhoto.filter(a => a._id !== action.payload._id)
            }
        default:
            return state
    };
};

export const PirincPhotoContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(pirincPhotoReducer, {
        pirincPhoto: null
    });

    return (
        <PirincPhotoContext.Provider value={{ ...state, dispatch }}>
            {children}
        </PirincPhotoContext.Provider>
    );
};