import React from "react";

function Header(props) {

    const {
        id,
        photoName,
        alt,
        title,
        page,
        onClickEdit,
        onClickEditEng,
        onClickEditFra,
        onClickEditGer,
        onClickEditDut
    } = props;

    const appendThumbToFileName = (fileName) => {
        const dotIndex = fileName.lastIndexOf(".");
        if (dotIndex !== -1) {
            const newFileName = fileName.slice(0, dotIndex) + "_thumb" + fileName.slice(dotIndex);
            return newFileName;
        } else {
            console.log("Dosya uzantısı bulunamadı.");
            return null;
        }
    };

    return (
        <div className="card customSliders" style={{marginTop: "50px"}}>
            <div className="card-body" style={{marginTop: "50px"}}>
                <div className="d-flex align-items-center justify-content-between mt-5">
                    <div className="lesson_name">
                        <div className="project-block">
                            <img
                                src={process.env.PUBLIC_URL + `/uploads/headers/${appendThumbToFileName(photoName)}`}
                                style={{
                                    width: "90%",
                                    borderRadius: "8px",
                                    objectFit: "cover"
                                }}
                            />
                        </div>
                        <h6 className="mb-0 fw-bold fs-6 mb-4 text-primary">{page}</h6>
                        <h6 className="mb-0 fw-bold fs-6 mb-2">{"Başlık => " + title}</h6>
                        <div className="g-2 pt-4">
                            <div className="d-flex align-items-center">
                                <div className="text-primary">{"SEO => "}</div>
                                <span className="ms-2">{alt}</span>
                            </div>
                        </div>
                    </div>
                    <div className="btn-group-vertical" role="group" aria-label="Basic outlined example">
                        <button type="button" className="btn btn-outline-secondary" onClick={onClickEdit}>
                            <i className="icofont-edit text-success"></i>
                        </button>
                        <button
                            type="button"
                            className="btn btn-outline-secondary"
                            title="İngilizce Güncelleme"
                            onClick={onClickEditEng}
                        >
                            <img src={process.env.PUBLIC_URL + `/flags/united-kingdom.png`} style={{ width: "15px" }} />
                        </button>
                        <button
                            type="button"
                            className="btn btn-outline-secondary"
                            title="Fransızca Güncelleme"
                            onClick={onClickEditFra}
                        >
                            <img src={process.env.PUBLIC_URL + `/flags/france.png`} style={{ width: "15px" }} />
                        </button>
                        <button
                            type="button"
                            className="btn btn-outline-secondary"
                            title="Almanca Güncelleme"
                            onClick={onClickEditGer}
                        >
                            <img src={process.env.PUBLIC_URL + `/flags/germany.png`} style={{ width: "15px" }} />
                        </button>
                        <button
                            type="button"
                            className="btn btn-outline-secondary"
                            title="Felemenkçe Güncelleme"
                            onClick={onClickEditDut}
                        >
                            <img src={process.env.PUBLIC_URL + `/flags/netherlands.png`} style={{ width: "15px" }} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Header;