import { YieldPhotoContext } from "../context/YieldPhotoContext";
import { useContext } from "react";

export const useYieldPhotoContext = () => {
    const context = useContext(YieldPhotoContext);

    if (!context) {
        return Error('useYieldPhotoContext must be used inside an YieldPhotoContextProvider');
    };

    return context;
};