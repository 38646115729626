import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import PageHeader from "../../components/common/PageHeader";
import { useXhr } from "hooks/useXhr";
import { useHealthyLifeContext } from "hooks/useHealthyLifeContext";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Button, Spinner } from "react-bootstrap";

function HealthyLife() {
    //States
    const [item, setItem] = useState({
        photos: [],
        alt: null,
        title: null,
        titleEng: null,
        titleFra: null,
        titleGer: null,
        titleDut: null,
        text1: null,
        textEng: null,
        textFra: null,
        textGer: null,
        textDut: null,
        date1: null,
        videoLink: null
    });
    const [status, setStatus] = useState(false);
    const [isUpdate, setIsUpdate] = useState(null);
    const [isDelete, setIsDelete] = useState(null);
    const [isModal, setIsModal] = useState(false);
    const [deletedPhotos, setDeletedPhotos] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [lang, setLang] = useState(null);
    const [error, setError] = useState({
        field: null,
        note: null
    });

    //Hooks
    const { customXhr } = useXhr()
    const { healthyLife, dispatch } = useHealthyLifeContext();

    const requiredFields = ['alt', 'title', 'text1', 'date1'];
    const maxItemLimit = 2000;

    const handleDeletePhoto = (photoName) => {
        setIsUpdate(prevState => ({
            ...prevState,
            photoNames: prevState.photoNames.filter(name => name !== photoName)
        }));
        setDeletedPhotos(prevState => [...prevState, photoName]);
    };

    const handleTextInput = (e) => {
        setItem(prevState => ({
            ...prevState,
            text1: e
        }));
    };

    const handleTextInputUpdate = (e) => {
        if (lang === "tur") {
            setIsUpdate(prevState => ({
                ...prevState,
                text1: e
            }));
        } else if (lang === "eng") {
            setIsUpdate(prevState => ({
                ...prevState,
                textEng: e
            }));
        } else if (lang === "fra") {
            setIsUpdate(prevState => ({
                ...prevState,
                textFra: e
            }));
        } else if (lang === "ger") {
            setIsUpdate(prevState => ({
                ...prevState,
                textGer: e
            }));
        } else if (lang === "dut") {
            setIsUpdate(prevState => ({
                ...prevState,
                textDut: e
            }));
        }
    };

    const handleOnChange = (e) => {
        const { id, value } = e.target;

        setItem(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleOnUpdateChange = (e) => {
        const { id, value } = e.target;

        setIsUpdate(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const generateUniqueFileName = (file) => {
        const timestamp = Date.now();
        const randomNumber = Math.floor(Math.random() * 10000);
        const extension = file.name.split('.').pop();

        return `${timestamp}_${randomNumber}.${extension}`;
    };

    const handlePhotoInput = (e) => {
        const files = e.target.files;
        const id = e.target.id;

        const renamedFiles = Array.from(files).map(file => {
            const newFileName = generateUniqueFileName(file);
            return new File([file], newFileName, { type: file.type });
        });

        setItem(prevState => ({
            ...prevState,
            [id]: renamedFiles
        }));
    };

    const handlePhotoUpdate = (e) => {
        const files = e.target.files;
        const id = e.target.id;

        const renamedFiles = Array.from(files).map(file => {
            const newFileName = generateUniqueFileName(file);
            return new File([file], newFileName, { type: file.type });
        });

        setIsUpdate(prevState => ({
            ...prevState,
            [id]: renamedFiles
        }));
    };

    const handleDelete = async (id) => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('module', 'healthy_life');
        formData.append('action', 'delete');
        formData.append('id', id);

        const response = await customXhr(formData);

        if (response.status === "success") {
            setStatus(!status);
            setIsDelete(null);
            setIsLoading(false);
        };
    };

    const handleUpdate = async () => {
        for (const field of requiredFields) {
            if (!isUpdate[field] || isUpdate[field] === "") {
                return (
                    setError({
                        field: field,
                        note: `Zorunlu alan`
                    }),
                    setIsLoading(false)
                )
            }
        }
        setIsLoading(true);
        const formData = new FormData();
        formData.append('module', 'healthy_life');
        formData.append('action', 'update');

        if (isUpdate.photos && isUpdate.photos.length > 0) {
            isUpdate.photos.forEach((photo, index) => {
                formData.append(`updatedPhotos[${index}]`, photo);
            });
        };
        if (deletedPhotos && deletedPhotos.length > 0) {
            deletedPhotos.forEach((photo, index) => {
                formData.append(`deletedPhotos[${index}]`, photo);
            });
        };

        formData.append('id', isUpdate.id);
        formData.append('alt', isUpdate.alt);
        formData.append('title', isUpdate.title);
        formData.append('text1', isUpdate.text1);
        formData.append('titleEng', isUpdate.titleEng);
        formData.append('textEng', isUpdate.textEng);
        formData.append('titleFra', isUpdate.titleFra);
        formData.append('textFra', isUpdate.textFra);
        formData.append('titleGer', isUpdate.titleGer);
        formData.append('textGer', isUpdate.textGer);
        formData.append('titleDut', isUpdate.titleDut);
        formData.append('textDut', isUpdate.textDut);
        formData.append('date1', isUpdate.date1);
        formData.append('videoLink', isUpdate.videoLink);

        const response = await customXhr(formData);

        if (response.status === "success") {
            setIsUpdate(null);
            setLang(null);
            setDeletedPhotos([]);
            setStatus(!status);
            setError({
                field: null,
                note: null
            });
            setIsLoading(false);
        };
    };

    const handleSubmit = async () => {
        for (const field of requiredFields) {
            if (!item[field] || item[field] === "") {
                return (
                    setError({
                        field: field,
                        note: `Zorunlu alan`
                    }),
                    setIsLoading(false)
                )
            }
        }
        if (healthyLife.length === maxItemLimit) return (alert("Maksimum veri sınırına ulaşıldı. Lütfen NovusID ekibinden destek talep ediniz."));
        setIsLoading(true);
        const formData = new FormData();
        formData.append('module', 'healthy_life');
        formData.append('action', 'insert');

        if (item.photos && item.photos.length > 0) {
            item.photos.forEach((photo, index) => {
                formData.append(`photos[${index}]`, photo);
            });
        };

        formData.append('alt', item.alt);
        formData.append('title', item.title);
        formData.append('text1', item.text1);
        formData.append('titleEng', item.titleEng);
        formData.append('textEng', item.textEng);
        formData.append('titleFra', item.titleFra);
        formData.append('textFra', item.textFra);
        formData.append('titleGer', item.titleGer);
        formData.append('textGer', item.textGer);
        formData.append('titleDut', item.titleDut);
        formData.append('textDut', item.textDut);
        formData.append('date1', item.date1);
        formData.append('videoLink', item.videoLink);

        const response = await customXhr(formData);

        if (response.status === "success") {
            setItem({
                photos: [],
                alt: null,
                title: null,
                titleEng: null,
                titleFra: null,
                titleGer: null,
                titleDut: null,
                text1: null,
                textEng: null,
                textFra: null,
                textGer: null,
                textDut: null,
                videoLink: null
            });
            setIsModal(null);
            setStatus(!status);
            setError({
                field: null,
                note: null
            });
            setIsLoading(false);
        };
    };

    const getHealthyLife = async () => {
        const formData = new FormData();
        formData.append('module', 'healthy_life');
        formData.append('action', 'list');

        const response = await customXhr(formData);

        if (response) {
            dispatch({ type: 'SET_HEALTHY_LIFE', payload: response.data });
        };
    };

    useEffect(() => {
        getHealthyLife();
    }, [status]);

    const appendThumbToFileName = (fileName) => {
        const dotIndex = fileName.lastIndexOf(".");
        if (dotIndex !== -1) {
            const newFileName = fileName.slice(0, dotIndex) + "_thumb" + fileName.slice(dotIndex);
            return newFileName;
        } else {
            console.log("Dosya uzantısı bulunamadı.");
            return null;
        }
    };

    const formatDate = (dateTimeString) => {
        const date = new Date(dateTimeString);

        const formattedDate = new Intl.DateTimeFormat('tr-TR', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        }).format(date);

        return formattedDate;
    };

    //Columns
    var columnT = ""

    columnT = [
        {
            name: "BAŞLIK",
            selector: (row) => row.title,
            sortable: true,
            cell: (row) => <a href="#" className="fw-bold text-primary">{row.title}</a>
        },
        {
            name: "FOTOĞRAFLAR",
            selector: (row) => row.photoNames,
            sortable: true,
            cell: row => <div className="row mediaPhotos">
                {row.photoNames.slice(0, 6).map(photoName => (
                    <div className="col-2" >
                        {photoName !== '' &&
                            <img
                                key={photoName}
                                src={process.env.PUBLIC_URL + `/uploads/healthy_life/${appendThumbToFileName(photoName)}`}
                            />
                        }
                    </div>
                ))}
            </div>,
            minWidth: "650px"
        },
        {
            name: "VİDEO LİNK",
            selector: (row) => row.videoLink,
            sortable: true
        },
        {
            name: "PAYLAŞIM TARİHİ",
            selector: (row) => formatDate(row.date1),
            sortable: true,
            width: "180px"
        },
        {
            name: "AKSİYON",
            selector: (row) => { },
            sortable: true,
            cell: (row) => <div className="btn-group" role="group" aria-label="Basic outlined example">
                <button
                    type="button"
                    className="btn btn-outline-secondary"
                    title="Türkçe Güncelleme"
                    onClick={() => { setIsUpdate(row); setLang("tur"); }}
                >
                    <i className="icofont-edit text-success"></i>
                </button>
                <button
                    type="button"
                    className="btn btn-outline-secondary"
                    title="İngilizce Güncelleme"
                    onClick={() => { setIsUpdate(row); setLang("eng"); }}
                >
                    <img src={process.env.PUBLIC_URL + `/flags/united-kingdom.png`} style={{ width: "15px" }} />
                </button>
                <button
                    type="button"
                    className="btn btn-outline-secondary"
                    title="Fransızca Güncelleme"
                    onClick={() => { setIsUpdate(row); setLang("fra"); }}
                >
                    <img src={process.env.PUBLIC_URL + `/flags/france.png`} style={{ width: "15px" }} />
                </button>
                <button
                    type="button"
                    className="btn btn-outline-secondary"
                    title="Almanca Güncelleme"
                    onClick={() => { setIsUpdate(row); setLang("ger"); }}
                >
                    <img src={process.env.PUBLIC_URL + `/flags/germany.png`} style={{ width: "15px" }} />
                </button>
                <button
                    type="button"
                    className="btn btn-outline-secondary"
                    title="Felemenkçe Güncelleme"
                    onClick={() => { setIsUpdate(row); setLang("dut"); }}
                >
                    <img src={process.env.PUBLIC_URL + `/flags/netherlands.png`} style={{ width: "15px" }} />
                </button>
                <button
                    type="button"
                    className="btn btn-outline-secondary deleterow"
                    title="Sil"
                    onClick={() => setIsDelete(row)}
                >
                    <i className="icofont-ui-delete text-danger"></i>
                </button>
            </div>
        }
    ]

    return (
        <div className="container-xxl">
            <PageHeader headerTitle="Sağlıklı Yaşam" renderRight={() => {
                return <div className="col-auto d-flex w-sm-100">
                    <button className="btn btn-dark btn-set-task w-sm-100" onClick={() => { setIsModal(true) }}>
                        <i className="icofont-plus-circle me-2 fs-6"></i>
                        Yeni Paylaşım
                    </button>
                </div>
            }} />
            <div className="row clearfix g-3">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            {healthyLife &&
                                <DataTable
                                    title="Sağlıklı Yaşam"
                                    columns={columnT}
                                    data={[...healthyLife].reverse()}
                                    defaultSortField="title"
                                    pagination
                                    selectableRows={false}
                                    className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
                                    highlightOnHover={true}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={isModal}
                onHide={() => {
                    setIsModal(null);
                    setItem({
                        photos: [],
                        alt: null,
                        title: null,
                        titleEng: null,
                        titleFra: null,
                        titleGer: null,
                        titleDut: null,
                        text1: null,
                        textEng: null,
                        textFra: null,
                        textGer: null,
                        textDut: null,
                        date1: null,
                        videoLink: null
                    });
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
                dialogClassName="modal-xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Yeni Paylaşım</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="title" className="form-label">Başlık</label>
                        <input
                            type="text"
                            className="form-control"
                            id="title"
                            value={item && item.title}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "title" ? "red" : "var(--border-color)"}` }}
                        />
                        {error.field === "title" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                    <div className="mb-3">
                        <label htmlFor="text1" className="form-label">
                            {"Yazı"}
                        </label>
                        {
                            item &&
                            <ReactQuill
                                theme="snow"
                                id="text1"
                                value={item.text1}
                                onChange={handleTextInput}
                                style={{ borderColor: `${error.field === "text1" ? "red" : "var(--border-color)"}` }}
                                modules={{
                                    toolbar: [
                                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                        [{ 'size': [] }],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                        { 'indent': '-1' }, { 'indent': '+1' }],
                                        ['link'],
                                        ['clean'],
                                        [{ 'color': [] }, { 'background': [] }], // Yazı rengi ve arkaplan rengi seçimi
                                    ]
                                }}
                            />
                        }
                        {error.field === "text1" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                    <div className="row g-3 mb-3">
                        <div className="col-sm">
                            <label htmlFor="date1" className="form-label">Paylaşım Tarihi</label>
                            <input
                                type="date"
                                className="form-control"
                                id="date1"
                                value={item && item.date1}
                                onChange={handleOnChange}
                            />
                            {error.field === "date1" &&
                                <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                            }
                        </div>
                        <div className="col-sm">
                            <label htmlFor="videoLink" className="form-label">Video Link</label>
                            <input
                                type="text"
                                className="form-control"
                                id="videoLink"
                                value={item && item.videoLink}
                                onChange={handleOnChange}
                            />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="alt" className="form-label">
                            {"Seo Kelimeler "}
                            <span className="text-danger">
                                (Lütfen 5 Kelimeden Fazla Yazmayınız!)
                            </span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="alt"
                            value={item && item.alt}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "alt" ? "red" : "var(--border-color)"}` }}
                        />
                        {error.field === "alt" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                    <div className="mb-3">
                        <label htmlFor="photos" className="form-label">Fotoğrafları Yükle</label>
                        <input
                            className="form-control"
                            type="file"
                            id="photos"
                            onChange={handlePhotoInput}
                            multiple
                        />
                        <div className="form-label" style={{ color: "red" }}>
                            {"Bu alanın geçerli görsel öğe boyutu: 1920x970"}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleSubmit()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "tur"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setDeletedPhotos([]);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
                dialogClassName="modal-xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Paylaşımı Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="title" className="form-label">Başlık</label>
                        <input
                            type="text"
                            className="form-control"
                            id="title"
                            value={isUpdate && isUpdate.title}
                            onChange={handleOnUpdateChange}
                            style={{ borderColor: `${error.field === "title" ? "red" : "var(--border-color)"}` }}
                        />
                        {error.field === "title" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                    <div className="mb-3">
                        <label htmlFor="text1" className="form-label">
                            {"Yazı"}
                        </label>
                        {
                            isUpdate &&
                            <ReactQuill
                                theme="snow"
                                id="text1"
                                rows="10"
                                value={isUpdate.text1}
                                onChange={handleTextInputUpdate}
                                style={{ borderColor: `${error.field === "text1" ? "red" : "var(--border-color)"}` }}
                                modules={{
                                    toolbar: [
                                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                        [{ size: [] }],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                        { 'indent': '-1' }, { 'indent': '+1' }],
                                        ['link'],
                                        ['clean'],
                                        [{ 'color': [] }, { 'background': [] }], // Yazı rengi ve arkaplan rengi seçimi
                                    ],
                                }}
                            />
                        }
                        {error.field === "text1" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                    <div className="row g-3 mb-3">
                        <div className="col-sm">
                            <label htmlFor="date1" className="form-label">Paylaşım Tarihi</label>
                            <input
                                type="date"
                                className="form-control"
                                id="date1"
                                value={isUpdate && isUpdate.date1}
                                onChange={handleOnUpdateChange}
                            />
                            {error.field === "date1" &&
                                <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                            }
                        </div>
                        <div className="col-sm">
                            <label htmlFor="videoLink" className="form-label">Video Link</label>
                            <input
                                type="text"
                                className="form-control"
                                id="videoLink"
                                value={isUpdate && isUpdate.videoLink}
                                onChange={handleOnUpdateChange}
                            />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="alt" className="form-label">
                            {"Seo Kelimeler "}
                            <span className="text-danger">
                                (Lütfen 5 Kelimeden Fazla Yazmayınız!)
                            </span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="alt"
                            value={isUpdate && isUpdate.alt}
                            onChange={handleOnUpdateChange}
                            style={{ borderColor: `${error.field === "alt" ? "red" : "var(--border-color)"}` }}
                        />
                        {error.field === "alt" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                    <div className="mb-3">
                        <label htmlFor="photos" className="form-label">Fotoğrafları Yükle</label>
                        <input
                            className="form-control"
                            type="file"
                            id="photos"
                            onChange={handlePhotoUpdate}
                            multiple
                        />
                        <div className="form-label" style={{ color: "red" }}>
                            {"Bu alanın geçerli görsel öğe boyutu: 1920x970"}
                        </div>
                    </div>
                    {
                        isUpdate &&
                        <>
                            {
                                isUpdate.photoNames &&
                                <div className="mb-3">
                                    <label htmlFor="photos" className="form-label">Fotoğrafları Seç</label>
                                    <div className="row photoUpdate">
                                        {isUpdate.photoNames.map(photoName => (
                                            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6 col-xss-12 mb-1 d-flex justify-content-center">
                                                <div>
                                                    <img
                                                        key={photoName}
                                                        src={process.env.PUBLIC_URL + `/uploads/healthy_life/${photoName}`}
                                                    />
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-secondary"
                                                        title="Sil"
                                                        onClick={() => handleDeletePhoto(photoName)}
                                                    >
                                                        <i className="icofont-ui-delete text-danger"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleUpdate()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "eng"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setDeletedPhotos([]);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
                dialogClassName="modal-xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">İngilizce Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        isUpdate &&
                        <>
                            <div className="mb-3">
                                <label htmlFor="titleEng" className="form-label">Başlık</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="titleEng"
                                    value={isUpdate && isUpdate.titleEng}
                                    onChange={handleOnUpdateChange}
                                    style={{ borderColor: `${error.field === "titleEng" ? "red" : "var(--border-color)"}` }}
                                />
                                {error.field === "titleEng" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                            <div className="mb-3">
                                <label htmlFor="textEng" className="form-label">
                                    {"Yazı"}
                                </label>
                                {
                                    isUpdate &&
                                    <ReactQuill
                                        theme="snow"
                                        id="textEng"
                                        rows="10"
                                        value={isUpdate.textEng}
                                        onChange={handleTextInputUpdate}
                                        style={{ borderColor: `${error.field === "textEng" ? "red" : "var(--border-color)"}` }}
                                        modules={{
                                            toolbar: [
                                                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                                [{ size: [] }],
                                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                                { 'indent': '-1' }, { 'indent': '+1' }],
                                                ['link'],
                                                ['clean'],
                                                [{ 'color': [] }, { 'background': [] }], // Yazı rengi ve arkaplan rengi seçimi
                                            ],
                                        }}
                                    />
                                }
                                {error.field === "textEng" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleUpdate()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "fra"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setDeletedPhotos([]);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
                dialogClassName="modal-xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Fransızca Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        isUpdate &&
                        <>
                            <div className="mb-3">
                                <label htmlFor="titleFra" className="form-label">Başlık</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="titleFra"
                                    value={isUpdate && isUpdate.titleFra}
                                    onChange={handleOnUpdateChange}
                                    style={{ borderColor: `${error.field === "titleFra" ? "red" : "var(--border-color)"}` }}
                                />
                                {error.field === "titleFra" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                            <div className="mb-3">
                                <label htmlFor="textFra" className="form-label">
                                    {"Yazı"}
                                </label>
                                {
                                    isUpdate &&
                                    <ReactQuill
                                        theme="snow"
                                        id="textFra"
                                        rows="10"
                                        value={isUpdate.textFra}
                                        onChange={handleTextInputUpdate}
                                        style={{ borderColor: `${error.field === "textFra" ? "red" : "var(--border-color)"}` }}
                                        modules={{
                                            toolbar: [
                                                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                                [{ size: [] }],
                                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                                { 'indent': '-1' }, { 'indent': '+1' }],
                                                ['link'],
                                                ['clean'],
                                                [{ 'color': [] }, { 'background': [] }], // Yazı rengi ve arkaplan rengi seçimi
                                            ],
                                        }}
                                    />
                                }
                                {error.field === "textFra" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleUpdate()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "ger"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setDeletedPhotos([]);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
                dialogClassName="modal-xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Almanca Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        isUpdate &&
                        <>
                            <div className="mb-3">
                                <label htmlFor="titleGer" className="form-label">Başlık</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="titleGer"
                                    value={isUpdate && isUpdate.titleGer}
                                    onChange={handleOnUpdateChange}
                                    style={{ borderColor: `${error.field === "titleGer" ? "red" : "var(--border-color)"}` }}
                                />
                                {error.field === "titleGer" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                            <div className="mb-3">
                                <label htmlFor="textGer" className="form-label">
                                    {"Yazı"}
                                </label>
                                {
                                    isUpdate &&
                                    <ReactQuill
                                        theme="snow"
                                        id="textGer"
                                        rows="10"
                                        value={isUpdate.textGer}
                                        onChange={handleTextInputUpdate}
                                        style={{ borderColor: `${error.field === "textGer" ? "red" : "var(--border-color)"}` }}
                                        modules={{
                                            toolbar: [
                                                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                                [{ size: [] }],
                                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                                { 'indent': '-1' }, { 'indent': '+1' }],
                                                ['link'],
                                                ['clean'],
                                                [{ 'color': [] }, { 'background': [] }], // Yazı rengi ve arkaplan rengi seçimi
                                            ],
                                        }}
                                    />
                                }
                                {error.field === "textGer" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleUpdate()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "dut"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setDeletedPhotos([]);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
                dialogClassName="modal-xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Felemenkçe Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        isUpdate &&
                        <>
                            <div className="mb-3">
                                <label htmlFor="titleDut" className="form-label">Başlık</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="titleDut"
                                    value={isUpdate && isUpdate.titleDut}
                                    onChange={handleOnUpdateChange}
                                    style={{ borderColor: `${error.field === "titleDut" ? "red" : "var(--border-color)"}` }}
                                />
                                {error.field === "titleDut" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                            <div className="mb-3">
                                <label htmlFor="textDut" className="form-label">
                                    {"Yazı"}
                                </label>
                                {
                                    isUpdate &&
                                    <ReactQuill
                                        theme="snow"
                                        id="textDut"
                                        rows="10"
                                        value={isUpdate.textDut}
                                        onChange={handleTextInputUpdate}
                                        style={{ borderColor: `${error.field === "textDut" ? "red" : "var(--border-color)"}` }}
                                        modules={{
                                            toolbar: [
                                                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                                [{ size: [] }],
                                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                                { 'indent': '-1' }, { 'indent': '+1' }],
                                                ['link'],
                                                ['clean'],
                                                [{ 'color': [] }, { 'background': [] }], // Yazı rengi ve arkaplan rengi seçimi
                                            ],
                                        }}
                                    />
                                }
                                {error.field === "textDut" &&
                                    <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                }
                            </div>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleUpdate()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                centered
                show={isDelete}
                onHide={() => {
                    setIsDelete(null);
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Uyarı</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <div className="form-label">
                            Bu paylaşımı silmek istediğinize emin misiniz?
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="danger"
                        className="me-1 text-white"
                        onClick={() => handleDelete(isDelete.id)}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Siliniyor...
                                </> :
                                <>
                                    <i className="icofont-ui-delete"></i>
                                    &ensp;
                                    Sil
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default HealthyLife;