import { createContext, useReducer } from "react";

export const HealthyLifeContext = createContext();

export const healthyLifeReducer = (state, action) => {
    switch (action.type) {
        case 'SET_HEALTHY_LIFE':
            return {
                healthyLife: action.payload
            }
        case 'CREATE_HEALTHY_LIFE':
            return {
                healthyLife: [action.payload, ...state.healthyLife]
            }
        case 'DELETE_HEALTHY_LIFE':
            return {
                healthyLife: state.healthyLife.filter(a => a._id !== action.payload._id)
            }
        default:
            return state
    };
};

export const HealthyLifeContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(healthyLifeReducer, {
        healthyLife: null
    });

    return (
        <HealthyLifeContext.Provider value={{ ...state, dispatch }}>
            {children}
        </HealthyLifeContext.Provider>
    );
};