import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import PageHeader from "../../components/common/PageHeader";
import { useXhr } from "hooks/useXhr";
import { usePrinciplesContext } from "hooks/usePrinciplesContext";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Button, Spinner } from "react-bootstrap";

function Principles() {
    //States
    const [status, setStatus] = useState(false);
    const [isUpdate, setIsUpdate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [lang, setLang] = useState(null);
    const [error, setError] = useState({
        field: null,
        note: null
    });

    //Hooks
    const { customXhr } = useXhr()
    const { principles, dispatch } = usePrinciplesContext();

    const requiredFields = ['alt', 'vision', 'mission', 'goals'];

    const handleOnChange = (e) => {
        const { id, value } = e.target;

        setIsUpdate(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleVision = (e) => {
        if (lang === "tur") {
            setIsUpdate(prevState => ({
                ...prevState,
                vision: e
            }));
        } else if (lang === "eng") {
            setIsUpdate(prevState => ({
                ...prevState,
                visionEng: e
            }));
        } else if (lang === "fra") {
            setIsUpdate(prevState => ({
                ...prevState,
                visionFra: e
            }));
        } else if (lang === "ger") {
            setIsUpdate(prevState => ({
                ...prevState,
                visionGer: e
            }));
        } else if (lang === "dut") {
            setIsUpdate(prevState => ({
                ...prevState,
                visionDut: e
            }));
        }
    };

    const handleMission = (e) => {
        if (lang === "tur") {
            setIsUpdate(prevState => ({
                ...prevState,
                mission: e
            }));
        } else if (lang === "eng") {
            setIsUpdate(prevState => ({
                ...prevState,
                missionEng: e
            }));
        } else if (lang === "fra") {
            setIsUpdate(prevState => ({
                ...prevState,
                missionFra: e
            }));
        } else if (lang === "ger") {
            setIsUpdate(prevState => ({
                ...prevState,
                missionGer: e
            }));
        } else if (lang === "dut") {
            setIsUpdate(prevState => ({
                ...prevState,
                missionDut: e
            }));
        }
        setIsUpdate(prevState => ({
            ...prevState,
            mission: e
        }));
    };

    const handleGoals = (e) => {
        if (lang === "tur") {
            setIsUpdate(prevState => ({
                ...prevState,
                goals: e
            }));
        } else if (lang === "eng") {
            setIsUpdate(prevState => ({
                ...prevState,
                goalsEng: e
            }));
        } else if (lang === "fra") {
            setIsUpdate(prevState => ({
                ...prevState,
                goalsFra: e
            }));
        } else if (lang === "ger") {
            setIsUpdate(prevState => ({
                ...prevState,
                goalsGer: e
            }));
        } else if (lang === "dut") {
            setIsUpdate(prevState => ({
                ...prevState,
                goalsDut: e
            }));
        }
    };

    const handlePhotoInput = (e) => {
        const file = e.target.files[0];
        const id = e.target.id;

        setIsUpdate(prevState => ({
            ...prevState,
            [id]: file
        }));
    };

    const handleSubmit = async () => {
        for (const field of requiredFields) {
            if (!isUpdate[field] || isUpdate[field] === "") {
                return (
                    setError({
                        field: field,
                        note: `Zorunlu alan`
                    }),
                    setIsLoading(false)
                )
            }
        }
        setIsLoading(true);
        const formData = new FormData();
        formData.append('module', 'principles');
        formData.append('action', 'update');
        formData.append('id', isUpdate.id);
        formData.append('photo', isUpdate.photo);
        formData.append('alt', isUpdate.alt);
        formData.append('vision', isUpdate.vision);
        formData.append('mission', isUpdate.mission);
        formData.append('goals', isUpdate.goals);
        formData.append('visionEng', isUpdate.visionEng);
        formData.append('missionEng', isUpdate.missionEng);
        formData.append('goalsEng', isUpdate.goalsEng);
        formData.append('visionFra', isUpdate.visionFra);
        formData.append('missionFra', isUpdate.missionFra);
        formData.append('goalsFra', isUpdate.goalsFra);
        formData.append('visionGer', isUpdate.visionGer);
        formData.append('missionGer', isUpdate.missionGer);
        formData.append('goalsGer', isUpdate.goalsGer);
        formData.append('visionDut', isUpdate.visionDut);
        formData.append('missionDut', isUpdate.missionDut);
        formData.append('goalsDut', isUpdate.goalsDut);

        const response = await customXhr(formData);

        if (response.status === "success") {
            setIsUpdate(null);
            setLang(null);
            setStatus(!status);
            setError({
                field: null,
                note: null
            });
            setIsLoading(false);
        };
    };

    const getPrinciples = async () => {
        const formData = new FormData();
        formData.append('module', 'principles');
        formData.append('action', 'list');

        const response = await customXhr(formData);

        if (response) {
            dispatch({ type: 'SET_PRINCIPLES', payload: response[0] });
        };
    };

    useEffect(() => {
        getPrinciples();
    }, [status]);

    return (
        <div className="container-xxl">
            <PageHeader headerTitle="Prensipler" />
            <div className="row align-items-center">
                <div className="col-lg-12 col-md-12 flex-column">
                    <div className="row g-3 gy-5 py-3 row-deck d-flex justify-content-center">
                        {
                            principles &&
                            <div className="col-lg-8 col-12">
                                <div className="card customPrinciples">
                                    <div className="card-body">
                                        <div className="d-flex align-items-center justify-content-between mt-5">
                                            <div className="lesson_name">
                                                <div className="project-block">
                                                    <img src={process.env.PUBLIC_URL + `/uploads/principles/${principles.photoName}`} style={{ width: "90%", borderRadius: "8px" }} />
                                                </div>
                                            </div>
                                            <div className="btn-group" role="group" aria-label="Basic outlined example">
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-secondary"
                                                    title="Türkçe Güncelleme"
                                                    onClick={() => { setIsUpdate(principles); setLang("tur"); }}
                                                >
                                                    <i className="icofont-edit text-success"></i>
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-secondary"
                                                    title="İngilizce Güncelleme"
                                                    onClick={() => { setIsUpdate(principles); setLang("eng"); }}
                                                >
                                                    <img src={process.env.PUBLIC_URL + `/flags/united-kingdom.png`} style={{ width: "15px" }} />
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-secondary"
                                                    title="Fransızca Güncelleme"
                                                    onClick={() => { setIsUpdate(principles); setLang("fra"); }}
                                                >
                                                    <img src={process.env.PUBLIC_URL + `/flags/france.png`} style={{ width: "15px" }} />
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-secondary"
                                                    title="Almanca Güncelleme"
                                                    onClick={() => { setIsUpdate(principles); setLang("ger"); }}
                                                >
                                                    <img src={process.env.PUBLIC_URL + `/flags/germany.png`} style={{ width: "15px" }} />
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-secondary"
                                                    title="Felemenkçe Güncelleme"
                                                    onClick={() => { setIsUpdate(principles); setLang("dut"); }}
                                                >
                                                    <img src={process.env.PUBLIC_URL + `/flags/netherlands.png`} style={{ width: "15px" }} />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row g-2 pt-4">
                                            <div className="col-12">
                                                <h4 className="text-primary">Vizyon</h4>
                                                <div className="ms-2 dangerousText" dangerouslySetInnerHTML={{ __html: principles.vision }} />
                                            </div>
                                        </div>
                                        <div className="row g-2 pt-4">
                                            <div className="col-12">
                                                <h4 className="text-primary">Misyon</h4>
                                                <div className="ms-2 dangerousText" dangerouslySetInnerHTML={{ __html: principles.mission }} />
                                            </div>
                                        </div>
                                        <div className="row g-2 pt-4">
                                            <div className="col-12">
                                                <h4 className="text-primary">Hedefler</h4>
                                                <div className="ms-2 dangerousText" dangerouslySetInnerHTML={{ __html: principles.goals }} />
                                            </div>
                                        </div>
                                        <div className="row g-2 pt-4">
                                            <div className="col-12">
                                                <div className="d-flex align-items-center">
                                                    <div className="text-primary">{"SEO => "}</div>
                                                    <span className="ms-2">{principles.alt}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Modal
                show={lang === "tur"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
                dialogClassName="modal-xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Prensipleri Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="vision" className="form-label">
                            {"Vizyon"}
                        </label>
                        {isUpdate &&
                            <ReactQuill
                                theme="snow"
                                id="vision"
                                value={isUpdate.vision}
                                onChange={handleVision}
                                style={{ borderColor: `${error.field === "vision" ? "red" : "var(--border-color)"}` }}
                                modules={{
                                    toolbar: [
                                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                        [{ 'size': [] }],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                        { 'indent': '-1' }, { 'indent': '+1' }],
                                        ['link'],
                                        ['clean'],
                                        [{ 'color': [] }, { 'background': [] }]
                                    ]
                                }}
                            />}
                        {error.field === "vision" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                    <div className="mb-3">
                        <label htmlFor="mission" className="form-label">
                            {"Misyon"}
                        </label>
                        {isUpdate &&
                            <ReactQuill
                                theme="snow"
                                id="mission"
                                value={isUpdate.mission}
                                onChange={handleMission}
                                style={{ borderColor: `${error.field === "mission" ? "red" : "var(--border-color)"}` }}
                                modules={{
                                    toolbar: [
                                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                        [{ 'size': [] }],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                        { 'indent': '-1' }, { 'indent': '+1' }],
                                        ['link'],
                                        ['clean'],
                                        [{ 'color': [] }, { 'background': [] }]
                                    ]
                                }}
                            />}
                        {error.field === "mission" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                    <div className="mb-3">
                        <label htmlFor="goals" className="form-label">
                            {"Hedefler"}
                        </label>
                        {isUpdate &&
                            <ReactQuill
                                theme="snow"
                                id="goals"
                                value={isUpdate.goals}
                                onChange={handleGoals}
                                style={{ borderColor: `${error.field === "goals" ? "red" : "var(--border-color)"}` }}
                                modules={{
                                    toolbar: [
                                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                        [{ 'size': [] }],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                        { 'indent': '-1' }, { 'indent': '+1' }],
                                        ['link'],
                                        ['clean'],
                                        [{ 'color': [] }, { 'background': [] }]
                                    ]
                                }}
                            />}
                        {error.field === "goals" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                    <div className="mb-3">
                        <label htmlFor="alt" className="form-label">
                            {"Seo Kelimeler "}
                            <span className="text-danger">
                                (Lütfen 5 Kelimeden Fazla Yazmayınız!)
                            </span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="alt"
                            value={isUpdate && isUpdate.alt}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "alt" ? "red" : "var(--border-color)"}` }}
                        />
                    </div>
                    {error.field === "alt" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                    <div className="mb-3">
                        <label htmlFor="photo" className="form-label">Fotoğraf Yükle</label>
                        <input
                            className="form-control"
                            type="file"
                            id="photo"
                            onChange={handlePhotoInput}
                        />
                        <div className="form-label" style={{ color: "red" }}>
                            {"Bu alanın geçerli görsel öğe boyutu: 1920x970"}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleSubmit()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "eng"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
                dialogClassName="modal-xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">İngilizce Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="visionEng" className="form-label">
                            {"Vizyon"}
                        </label>
                        {isUpdate &&
                            <ReactQuill
                                theme="snow"
                                id="visionEng"
                                value={isUpdate.visionEng}
                                onChange={handleVision}
                                style={{ borderColor: `${error.field === "visionEng" ? "red" : "var(--border-color)"}` }}
                                modules={{
                                    toolbar: [
                                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                        [{ 'size': [] }],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                        { 'indent': '-1' }, { 'indent': '+1' }],
                                        ['link'],
                                        ['clean'],
                                        [{ 'color': [] }, { 'background': [] }]
                                    ]
                                }}
                            />}
                        {error.field === "visionEng" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                    <div className="mb-3">
                        <label htmlFor="missionEng" className="form-label">
                            {"Misyon"}
                        </label>
                        {isUpdate &&
                            <ReactQuill
                                theme="snow"
                                id="missionEng"
                                value={isUpdate.missionEng}
                                onChange={handleMission}
                                style={{ borderColor: `${error.field === "missionEng" ? "red" : "var(--border-color)"}` }}
                                modules={{
                                    toolbar: [
                                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                        [{ 'size': [] }],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                        { 'indent': '-1' }, { 'indent': '+1' }],
                                        ['link'],
                                        ['clean'],
                                        [{ 'color': [] }, { 'background': [] }]
                                    ]
                                }}
                            />}
                        {error.field === "missionEng" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                    <div className="mb-3">
                        <label htmlFor="goalsEng" className="form-label">
                            {"Hedefler"}
                        </label>
                        {isUpdate &&
                            <ReactQuill
                                theme="snow"
                                id="goalsEng"
                                value={isUpdate.goalsEng}
                                onChange={handleGoals}
                                style={{ borderColor: `${error.field === "goalsEng" ? "red" : "var(--border-color)"}` }}
                                modules={{
                                    toolbar: [
                                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                        [{ 'size': [] }],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                        { 'indent': '-1' }, { 'indent': '+1' }],
                                        ['link'],
                                        ['clean'],
                                        [{ 'color': [] }, { 'background': [] }]
                                    ]
                                }}
                            />}
                        {error.field === "goalsEng" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleSubmit()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "fra"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
                dialogClassName="modal-xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Fransızca Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="visionFra" className="form-label">
                            {"Vizyon"}
                        </label>
                        {isUpdate &&
                            <ReactQuill
                                theme="snow"
                                id="visionFra"
                                value={isUpdate.visionFra}
                                onChange={handleVision}
                                style={{ borderColor: `${error.field === "visionFra" ? "red" : "var(--border-color)"}` }}
                                modules={{
                                    toolbar: [
                                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                        [{ 'size': [] }],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                        { 'indent': '-1' }, { 'indent': '+1' }],
                                        ['link'],
                                        ['clean'],
                                        [{ 'color': [] }, { 'background': [] }]
                                    ]
                                }}
                            />}
                        {error.field === "visionFra" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                    <div className="mb-3">
                        <label htmlFor="missionFra" className="form-label">
                            {"Misyon"}
                        </label>
                        {isUpdate &&
                            <ReactQuill
                                theme="snow"
                                id="missionFra"
                                value={isUpdate.missionFra}
                                onChange={handleMission}
                                style={{ borderColor: `${error.field === "missionFra" ? "red" : "var(--border-color)"}` }}
                                modules={{
                                    toolbar: [
                                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                        [{ 'size': [] }],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                        { 'indent': '-1' }, { 'indent': '+1' }],
                                        ['link'],
                                        ['clean'],
                                        [{ 'color': [] }, { 'background': [] }]
                                    ]
                                }}
                            />}
                        {error.field === "missionFra" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                    <div className="mb-3">
                        <label htmlFor="goalsFra" className="form-label">
                            {"Hedefler"}
                        </label>
                        {isUpdate &&
                            <ReactQuill
                                theme="snow"
                                id="goalsFra"
                                value={isUpdate.goalsFra}
                                onChange={handleGoals}
                                style={{ borderColor: `${error.field === "goalsFra" ? "red" : "var(--border-color)"}` }}
                                modules={{
                                    toolbar: [
                                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                        [{ 'size': [] }],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                        { 'indent': '-1' }, { 'indent': '+1' }],
                                        ['link'],
                                        ['clean'],
                                        [{ 'color': [] }, { 'background': [] }]
                                    ]
                                }}
                            />}
                        {error.field === "goalsFra" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleSubmit()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "ger"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
                dialogClassName="modal-xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Almanca Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="visionGer" className="form-label">
                            {"Vizyon"}
                        </label>
                        {isUpdate &&
                            <ReactQuill
                                theme="snow"
                                id="visionGer"
                                value={isUpdate.visionGer}
                                onChange={handleVision}
                                style={{ borderColor: `${error.field === "visionGer" ? "red" : "var(--border-color)"}` }}
                                modules={{
                                    toolbar: [
                                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                        [{ 'size': [] }],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                        { 'indent': '-1' }, { 'indent': '+1' }],
                                        ['link'],
                                        ['clean'],
                                        [{ 'color': [] }, { 'background': [] }]
                                    ]
                                }}
                            />}
                        {error.field === "visionGer" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                    <div className="mb-3">
                        <label htmlFor="missionGer" className="form-label">
                            {"Misyon"}
                        </label>
                        {isUpdate &&
                            <ReactQuill
                                theme="snow"
                                id="missionGer"
                                value={isUpdate.missionGer}
                                onChange={handleMission}
                                style={{ borderColor: `${error.field === "missionGer" ? "red" : "var(--border-color)"}` }}
                                modules={{
                                    toolbar: [
                                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                        [{ 'size': [] }],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                        { 'indent': '-1' }, { 'indent': '+1' }],
                                        ['link'],
                                        ['clean'],
                                        [{ 'color': [] }, { 'background': [] }]
                                    ]
                                }}
                            />}
                        {error.field === "missionGer" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                    <div className="mb-3">
                        <label htmlFor="goalsGer" className="form-label">
                            {"Hedefler"}
                        </label>
                        {isUpdate &&
                            <ReactQuill
                                theme="snow"
                                id="goalsGer"
                                value={isUpdate.goalsGer}
                                onChange={handleGoals}
                                style={{ borderColor: `${error.field === "goalsGer" ? "red" : "var(--border-color)"}` }}
                                modules={{
                                    toolbar: [
                                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                        [{ 'size': [] }],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                        { 'indent': '-1' }, { 'indent': '+1' }],
                                        ['link'],
                                        ['clean'],
                                        [{ 'color': [] }, { 'background': [] }]
                                    ]
                                }}
                            />}
                        {error.field === "goalsGer" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleSubmit()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "dut"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
                dialogClassName="modal-xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Felemenkçe Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="visionDut" className="form-label">
                            {"Vizyon"}
                        </label>
                        {isUpdate &&
                            <ReactQuill
                                theme="snow"
                                id="visionDut"
                                value={isUpdate.visionDut}
                                onChange={handleVision}
                                style={{ borderColor: `${error.field === "visionDut" ? "red" : "var(--border-color)"}` }}
                                modules={{
                                    toolbar: [
                                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                        [{ 'size': [] }],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                        { 'indent': '-1' }, { 'indent': '+1' }],
                                        ['link'],
                                        ['clean'],
                                        [{ 'color': [] }, { 'background': [] }]
                                    ]
                                }}
                            />}
                        {error.field === "visionDut" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                    <div className="mb-3">
                        <label htmlFor="missionDut" className="form-label">
                            {"Misyon"}
                        </label>
                        {isUpdate &&
                            <ReactQuill
                                theme="snow"
                                id="missionDut"
                                value={isUpdate.missionDut}
                                onChange={handleMission}
                                style={{ borderColor: `${error.field === "missionDut" ? "red" : "var(--border-color)"}` }}
                                modules={{
                                    toolbar: [
                                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                        [{ 'size': [] }],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                        { 'indent': '-1' }, { 'indent': '+1' }],
                                        ['link'],
                                        ['clean'],
                                        [{ 'color': [] }, { 'background': [] }]
                                    ]
                                }}
                            />}
                        {error.field === "missionDut" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                    <div className="mb-3">
                        <label htmlFor="goalsDut" className="form-label">
                            {"Hedefler"}
                        </label>
                        {isUpdate &&
                            <ReactQuill
                                theme="snow"
                                id="goalsDut"
                                value={isUpdate.goalsDut}
                                onChange={handleGoals}
                                style={{ borderColor: `${error.field === "goalsDut" ? "red" : "var(--border-color)"}` }}
                                modules={{
                                    toolbar: [
                                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                        [{ 'size': [] }],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                        { 'indent': '-1' }, { 'indent': '+1' }],
                                        ['link'],
                                        ['clean'],
                                        [{ 'color': [] }, { 'background': [] }]
                                    ]
                                }}
                            />}
                        {error.field === "goalsDut" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleSubmit()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Principles;