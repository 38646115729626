import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import PageHeader from "../../components/common/PageHeader";
import { useXhr } from "hooks/useXhr";
import { useSustainabilityTextContext } from "hooks/useSustainabilityTextContext";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Button, Spinner } from "react-bootstrap";

function SustainabilityText() {
    //States
    const [status, setStatus] = useState(false);
    const [textUpdate, setTextUpdate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [lang, setLang] = useState(null);
    const [error, setError] = useState({
        field: null,
        note: null
    });

    //Hooks
    const { customXhr } = useXhr();
    const { sustainabilityText, dispatch } = useSustainabilityTextContext();

    const requiredFields = ['alt', 'title', 'text1'];

    //Functions
    const handleTextOnChange = (e) => {
        const { id, value } = e.target;

        setTextUpdate(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const generateUniqueFileName = (file) => {
        const timestamp = Date.now();
        const randomNumber = Math.floor(Math.random() * 10000);
        const extension = file.name.split('.').pop();

        return `${timestamp}_${randomNumber}.${extension}`;
    };

    const handleTextPhotoInput = (e) => {
        const file = e.target.files[0];
        const id = e.target.id;

        const newFileName = generateUniqueFileName(file);

        const newFile = new File([file], newFileName, { type: file.type });

        setTextUpdate(prevState => ({
            ...prevState,
            [id]: newFile
        }));
    };

    const handleTextInput = (e) => {
        if (lang === "tur") {
            setTextUpdate(prevState => ({
                ...prevState,
                text1: e
            }));
        } else if (lang === "eng") {
            setTextUpdate(prevState => ({
                ...prevState,
                textEng: e
            }));
        } else if (lang === "fra") {
            setTextUpdate(prevState => ({
                ...prevState,
                textFra: e
            }));
        } else if (lang === "ger") {
            setTextUpdate(prevState => ({
                ...prevState,
                textGer: e
            }));
        } else if (lang === "dut") {
            setTextUpdate(prevState => ({
                ...prevState,
                textDut: e
            }));
        }
    };

    const handleTextSubmit = async () => {
        for (const field of requiredFields) {
            if (!textUpdate[field] || textUpdate[field] === "") {
                return (
                    setError({
                        field: field,
                        note: `Zorunlu alan`
                    }),
                    setIsLoading(false)
                )
            }
        }
        setIsLoading(true);
        const formData = new FormData();
        formData.append('module', 'sustainability_text');
        formData.append('action', 'update');
        formData.append('id', textUpdate.id);
        formData.append('photo', textUpdate.photo);
        formData.append('alt', textUpdate.alt);
        formData.append('title', textUpdate.title);
        formData.append('text1', textUpdate.text1);
        formData.append('titleEng', textUpdate.titleEng);
        formData.append('textEng', textUpdate.textEng);
        formData.append('titleFra', textUpdate.titleFra);
        formData.append('textFra', textUpdate.textFra);
        formData.append('titleGer', textUpdate.titleGer);
        formData.append('textGer', textUpdate.textGer);
        formData.append('titleDut', textUpdate.titleDut);
        formData.append('textDut', textUpdate.textDut);

        const response = await customXhr(formData);

        if (response.status === "success") {
            setTextUpdate(null);
            setLang(null);
            setStatus(!status);
            setError({
                field: null,
                note: null
            });
            setIsLoading(false);
        };
    };

    const getSustainabilityText = async () => {
        const formData = new FormData();
        formData.append('module', 'sustainability_text');
        formData.append('action', 'list');

        const response = await customXhr(formData);

        if (response) {
            dispatch({ type: 'SET_SUSTAINABILITY_TEXT', payload: response[0] });
        };
    };

    useEffect(() => {
        getSustainabilityText();
    }, [status]);

    if (sustainabilityText) {
        return (
            <div className="container-xxl">
                <PageHeader headerTitle="Sürdürülebilirlik Yazı Bölümü" />
                <div className="row align-items-center">
                    <div className="col-lg-12 col-md-12 flex-column">
                        <div className="row g-3 gy-5 py-3 row-deck">

                            <div className="col-xxl-8 col-xl-8 col-lg-12 col-md-12 col-sm-12">
                                <div className="card customSliders" style={{ marginTop: "50px" }}>
                                    <div className="card-body" style={{ marginTop: "200px" }}>
                                        <div className="d-flex align-items-center justify-content-between mt-5">
                                            <div className="lesson_name">
                                                <div className="project-block" style={{ top: "-320px" }}>
                                                    <img
                                                        src={process.env.PUBLIC_URL + `/uploads/sustainability_text/${sustainabilityText.photoName}`}
                                                        style={{
                                                            width: "90%",
                                                            borderRadius: "8px",
                                                            height: "300px",
                                                            objectFit: "cover"
                                                        }}
                                                    />
                                                </div>
                                                <h6 className="mb-0 fw-bold  fs-6  mb-2">{sustainabilityText.title}</h6>
                                            </div>
                                            <div className="btn-group" role="group" aria-label="Basic outlined example">
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-secondary"
                                                    title="Türkçe Güncelleme"
                                                    onClick={() => { setTextUpdate(sustainabilityText); setLang("tur"); }}
                                                >
                                                    <i className="icofont-edit text-success"></i>
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-secondary"
                                                    title="İngilizce Güncelleme"
                                                    onClick={() => { setTextUpdate(sustainabilityText); setLang("eng"); }}
                                                >
                                                    <img src={process.env.PUBLIC_URL + `/flags/united-kingdom.png`} style={{ width: "15px" }} />
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-secondary"
                                                    title="Fransızca Güncelleme"
                                                    onClick={() => { setTextUpdate(sustainabilityText); setLang("fra"); }}
                                                >
                                                    <img src={process.env.PUBLIC_URL + `/flags/france.png`} style={{ width: "15px" }} />
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-secondary"
                                                    title="Almanca Güncelleme"
                                                    onClick={() => { setTextUpdate(sustainabilityText); setLang("ger"); }}
                                                >
                                                    <img src={process.env.PUBLIC_URL + `/flags/germany.png`} style={{ width: "15px" }} />
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-secondary"
                                                    title="Felemenkçe Güncelleme"
                                                    onClick={() => { setTextUpdate(sustainabilityText); setLang("dut"); }}
                                                >
                                                    <img src={process.env.PUBLIC_URL + `/flags/netherlands.png`} style={{ width: "15px" }} />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row g-2 pt-4">
                                            <div className="col-12">
                                                <div className="d-flex align-items-center">
                                                    <span className="ms-2 dangerousText" dangerouslySetInnerHTML={{ __html: sustainabilityText.text1 }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row g-2 pt-4">
                                            <div className="col-12">
                                                <div className="d-flex align-items-center">
                                                    <div className="text-primary">{"SEO => "}</div>
                                                    <span className="ms-2">{sustainabilityText.alt}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Modal
                    show={lang === "tur"}
                    onHide={() => {
                        setTextUpdate(null);
                        setLang(null);
                        setError({
                            field: null,
                            note: null
                        });
                        setIsLoading(false);
                    }}
                    dialogClassName="modal-xl"
                >
                    <Modal.Header closeButton>
                        <Modal.Title className="fw-bold">Yazı Bölümü Düzenle</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mb-3">
                            <label htmlFor="title" className="form-label">Başlık</label>
                            <input
                                type="text"
                                className="form-control"
                                id="title"
                                value={textUpdate && textUpdate.title}
                                onChange={handleTextOnChange}
                                style={{ borderColor: `${error.field === "title" ? "red" : "var(--border-color)"}` }}
                            />
                            {error.field === "title" &&
                                <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                            }
                        </div>
                        <div className="mb-3">
                            <label htmlFor="text1" className="form-label">
                                {"Yazı"}
                            </label>
                            {textUpdate &&
                                <ReactQuill
                                    theme="snow"
                                    id="text1"
                                    value={textUpdate.text1}
                                    onChange={handleTextInput}
                                    style={{ borderColor: `${error.field === "text1" ? "red" : "var(--border-color)"}` }}
                                    modules={{
                                        toolbar: [
                                            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                            [{ 'size': [] }],
                                            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                            [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                            { 'indent': '-1' }, { 'indent': '+1' }],
                                            ['link'],
                                            ['clean'],
                                            [{ 'color': [] }, { 'background': [] }],
                                        ]
                                    }}
                                />
                            }
                            {error.field === "text1" &&
                                <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                            }
                        </div>
                        <div className="mb-3">
                            <label htmlFor="alt" className="form-label">
                                {"Seo Kelimeler "}
                                <span className="text-danger">
                                    (Lütfen 5 Kelimeden Fazla Yazmayınız!)
                                </span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="alt"
                                value={textUpdate && textUpdate.alt}
                                onChange={handleTextOnChange}
                                style={{ borderColor: `${error.field === "alt" ? "red" : "var(--border-color)"}` }}
                            />
                            {error.field === "alt" &&
                                <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                            }
                        </div>
                        <div className="mb-3">
                            <label htmlFor="photo" className="form-label">Fotoğraf Yükle</label>
                            <input
                                className="form-control"
                                type="file"
                                id="photo"
                                onChange={handleTextPhotoInput}
                            />
                            <div className="form-label" style={{ color: "red" }}>
                                {"Bu alanın geçerli görsel öğe boyutu: 9253x3331"}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="primary"
                            className="me-1"
                            onClick={() => handleTextSubmit()}
                            disabled={isLoading}
                        >
                            {
                                isLoading ?
                                    <>
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                        Yükleniyor...
                                    </> :
                                    <>
                                        Kaydet
                                    </>
                            }
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={lang === "eng"}
                    onHide={() => {
                        setTextUpdate(null);
                        setLang(null);
                        setError({
                            field: null,
                            note: null
                        });
                        setIsLoading(false);
                    }}
                    dialogClassName="modal-xl"
                >
                    <Modal.Header closeButton>
                        <Modal.Title className="fw-bold">İngilizce Düzenle</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mb-3">
                            <label htmlFor="titleEng" className="form-label">Başlık</label>
                            <input
                                type="text"
                                className="form-control"
                                id="titleEng"
                                value={textUpdate && textUpdate.titleEng}
                                onChange={handleTextOnChange}
                                style={{ borderColor: `${error.field === "titleEng" ? "red" : "var(--border-color)"}` }}
                            />
                            {error.field === "titleEng" &&
                                <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                            }
                        </div>
                        <div className="mb-3">
                            <label htmlFor="textEng" className="form-label">
                                {"Yazı"}
                            </label>
                            {textUpdate &&
                                <ReactQuill
                                    theme="snow"
                                    id="textEng"
                                    value={textUpdate.textEng}
                                    onChange={handleTextInput}
                                    style={{ borderColor: `${error.field === "textEng" ? "red" : "var(--border-color)"}` }}
                                    modules={{
                                        toolbar: [
                                            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                            [{ 'size': [] }],
                                            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                            [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                            { 'indent': '-1' }, { 'indent': '+1' }],
                                            ['link'],
                                            ['clean'],
                                            [{ 'color': [] }, { 'background': [] }],
                                        ]
                                    }}
                                />
                            }
                            {error.field === "textEng" &&
                                <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="primary"
                            className="me-1"
                            onClick={() => handleTextSubmit()}
                            disabled={isLoading}
                        >
                            {
                                isLoading ?
                                    <>
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                        Yükleniyor...
                                    </> :
                                    <>
                                        Kaydet
                                    </>
                            }
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={lang === "fra"}
                    onHide={() => {
                        setTextUpdate(null);
                        setLang(null);
                        setError({
                            field: null,
                            note: null
                        });
                        setIsLoading(false);
                    }}
                    dialogClassName="modal-xl"
                >
                    <Modal.Header closeButton>
                        <Modal.Title className="fw-bold">Fransızca Düzenle</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mb-3">
                            <label htmlFor="titleFra" className="form-label">Başlık</label>
                            <input
                                type="text"
                                className="form-control"
                                id="titleFra"
                                value={textUpdate && textUpdate.titleFra}
                                onChange={handleTextOnChange}
                                style={{ borderColor: `${error.field === "titleFra" ? "red" : "var(--border-color)"}` }}
                            />
                            {error.field === "titleFra" &&
                                <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                            }
                        </div>
                        <div className="mb-3">
                            <label htmlFor="textFra" className="form-label">
                                {"Yazı"}
                            </label>
                            {textUpdate &&
                                <ReactQuill
                                    theme="snow"
                                    id="textFra"
                                    value={textUpdate.textFra}
                                    onChange={handleTextInput}
                                    style={{ borderColor: `${error.field === "textFra" ? "red" : "var(--border-color)"}` }}
                                    modules={{
                                        toolbar: [
                                            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                            [{ 'size': [] }],
                                            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                            [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                            { 'indent': '-1' }, { 'indent': '+1' }],
                                            ['link'],
                                            ['clean'],
                                            [{ 'color': [] }, { 'background': [] }],
                                        ]
                                    }}
                                />
                            }
                            {error.field === "textFra" &&
                                <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="primary"
                            className="me-1"
                            onClick={() => handleTextSubmit()}
                            disabled={isLoading}
                        >
                            {
                                isLoading ?
                                    <>
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                        Yükleniyor...
                                    </> :
                                    <>
                                        Kaydet
                                    </>
                            }
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={lang === "ger"}
                    onHide={() => {
                        setTextUpdate(null);
                        setLang(null);
                        setError({
                            field: null,
                            note: null
                        });
                        setIsLoading(false);
                    }}
                    dialogClassName="modal-xl"
                >
                    <Modal.Header closeButton>
                        <Modal.Title className="fw-bold">Almanca Düzenle</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mb-3">
                            <label htmlFor="titleGer" className="form-label">Başlık</label>
                            <input
                                type="text"
                                className="form-control"
                                id="titleGer"
                                value={textUpdate && textUpdate.titleGer}
                                onChange={handleTextOnChange}
                                style={{ borderColor: `${error.field === "titleGer" ? "red" : "var(--border-color)"}` }}
                            />
                            {error.field === "titleGer" &&
                                <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                            }
                        </div>
                        <div className="mb-3">
                            <label htmlFor="textGer" className="form-label">
                                {"Yazı"}
                            </label>
                            {textUpdate &&
                                <ReactQuill
                                    theme="snow"
                                    id="textGer"
                                    value={textUpdate.textGer}
                                    onChange={handleTextInput}
                                    style={{ borderColor: `${error.field === "textGer" ? "red" : "var(--border-color)"}` }}
                                    modules={{
                                        toolbar: [
                                            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                            [{ 'size': [] }],
                                            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                            [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                            { 'indent': '-1' }, { 'indent': '+1' }],
                                            ['link'],
                                            ['clean'],
                                            [{ 'color': [] }, { 'background': [] }],
                                        ]
                                    }}
                                />
                            }
                            {error.field === "textGer" &&
                                <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="primary"
                            className="me-1"
                            onClick={() => handleTextSubmit()}
                            disabled={isLoading}
                        >
                            {
                                isLoading ?
                                    <>
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                        Yükleniyor...
                                    </> :
                                    <>
                                        Kaydet
                                    </>
                            }
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={lang === "dut"}
                    onHide={() => {
                        setTextUpdate(null);
                        setLang(null);
                        setError({
                            field: null,
                            note: null
                        });
                        setIsLoading(false);
                    }}
                    dialogClassName="modal-xl"
                >
                    <Modal.Header closeButton>
                        <Modal.Title className="fw-bold">Felemenkçe Düzenle</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mb-3">
                            <label htmlFor="titleDut" className="form-label">Başlık</label>
                            <input
                                type="text"
                                className="form-control"
                                id="titleDut"
                                value={textUpdate && textUpdate.titleDut}
                                onChange={handleTextOnChange}
                                style={{ borderColor: `${error.field === "titleDut" ? "red" : "var(--border-color)"}` }}
                            />
                            {error.field === "titleDut" &&
                                <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                            }
                        </div>
                        <div className="mb-3">
                            <label htmlFor="textDut" className="form-label">
                                {"Yazı"}
                            </label>
                            {textUpdate &&
                                <ReactQuill
                                    theme="snow"
                                    id="textDut"
                                    value={textUpdate.textDut}
                                    onChange={handleTextInput}
                                    style={{ borderColor: `${error.field === "textDut" ? "red" : "var(--border-color)"}` }}
                                    modules={{
                                        toolbar: [
                                            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                            [{ 'size': [] }],
                                            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                            [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                            { 'indent': '-1' }, { 'indent': '+1' }],
                                            ['link'],
                                            ['clean'],
                                            [{ 'color': [] }, { 'background': [] }],
                                        ]
                                    }}
                                />
                            }
                            {error.field === "textDut" &&
                                <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="primary"
                            className="me-1"
                            onClick={() => handleTextSubmit()}
                            disabled={isLoading}
                        >
                            {
                                isLoading ?
                                    <>
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                        Yükleniyor...
                                    </> :
                                    <>
                                        Kaydet
                                    </>
                            }
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default SustainabilityText;