import { createContext, useReducer } from "react";

export const BulgurPhotoContext = createContext();

export const bulgurPhotoReducer = (state, action) => {
    switch (action.type) {
        case 'SET_BULGUR_PHOTO':
            return {
                bulgurPhoto: action.payload
            }
        case 'CREATE_BULGUR_PHOTO':
            return {
                bulgurPhoto: [action.payload, ...state.bulgurPhoto]
            }
        case 'DELETE_BULGUR_PHOTO':
            return {
                bulgurPhoto: state.bulgurPhoto.filter(a => a._id !== action.payload._id)
            }
        default:
            return state
    };
};

export const BulgurPhotoContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(bulgurPhotoReducer, {
        bulgurPhoto: null
    });

    return (
        <BulgurPhotoContext.Provider value={{ ...state, dispatch }}>
            {children}
        </BulgurPhotoContext.Provider>
    );
};