import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import PageHeader from "../../components/common/PageHeader";
import { useXhr } from "hooks/useXhr";
import { useCuriousThingsContext } from "hooks/useCuriousThingsContext";
import { Button, Spinner } from "react-bootstrap";

function CuriousThings() {
    //States
    const [item, setItem] = useState({
        title: null,
        text1: null,
        titleEng: null,
        textEng: null,
        titleFra: null,
        textFra: null,
        titleGer: null,
        textGer: null,
        titleDut: null,
        textDut: null
    });
    const [status, setStatus] = useState(false);
    const [isUpdate, setIsUpdate] = useState(null);
    const [lang, setLang] = useState(null);
    const [isDelete, setIsDelete] = useState(null);
    const [isModal, setIsModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState({
        field: null,
        note: null
    });

    //Hooks
    const { customXhr } = useXhr()
    const { curiousThings, dispatch } = useCuriousThingsContext();

    const requiredFields = ['title', 'text1'];

    const handleOnChange = (e) => {
        const { id, value } = e.target;

        setItem(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleOnUpdateChange = (e) => {
        const { id, value } = e.target;

        setIsUpdate(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleDelete = async (id) => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('module', 'curious_things');
        formData.append('action', 'delete');
        formData.append('id', id);

        const response = await customXhr(formData);

        if (response.status === "success") {
            setStatus(!status);
            setIsDelete(null);
            setIsLoading(false);
        };
    };

    const handleUpdate = async () => {
        for (const field of requiredFields) {
            if (!isUpdate[field] || isUpdate[field] === "") {
                return (
                    setError({
                        field: field,
                        note: `Zorunlu alan`
                    }),
                    setIsLoading(false)
                )
            }
        }
        setIsLoading(true);
        const formData = new FormData();
        formData.append('module', 'curious_things');
        formData.append('action', 'update');
        formData.append('id', isUpdate.id);
        formData.append('title', isUpdate.title);
        formData.append('text1', isUpdate.text1);
        formData.append('titleEng', isUpdate.titleEng);
        formData.append('textEng', isUpdate.textEng);
        formData.append('titleFra', isUpdate.titleFra);
        formData.append('textFra', isUpdate.textFra);
        formData.append('titleGer', isUpdate.titleGer);
        formData.append('textGer', isUpdate.textGer);
        formData.append('titleDut', isUpdate.titleDut);
        formData.append('textDut', isUpdate.textDut);

        const response = await customXhr(formData);

        if (response.status === "success") {
            setIsUpdate(null);
            setStatus(!status);
            setLang(null);
            setError({
                field: null,
                note: null
            });
            setIsLoading(false);
        };
    };

    const handleSubmit = async () => {
        for (const field of requiredFields) {
            if (!item[field] || item[field] === "") {
                return (
                    setError({
                        field: field,
                        note: `Zorunlu alan`
                    }),
                    setIsLoading(false)
                )
            }
        }
        setIsLoading(true);
        const formData = new FormData();
        formData.append('module', 'curious_things');
        formData.append('action', 'insert');
        formData.append('title', item.title);
        formData.append('text1', item.text1);
        formData.append('titleEng', item.titleEng);
        formData.append('textEng', item.textEng);
        formData.append('titleFra', item.titleFra);
        formData.append('textFra', item.textFra);
        formData.append('titleGer', item.titleGer);
        formData.append('textGer', item.textGer);
        formData.append('titleDut', item.titleDut);
        formData.append('textDut', item.textDut);

        const response = await customXhr(formData);

        if (response.status === "success") {
            setItem({
                title: null,
                text1: null
            });
            setIsModal(null);
            setStatus(!status);
            setError({
                field: null,
                note: null
            });
            setIsLoading(false);
        };
    };

    const getCuriousThings = async () => {
        const formData = new FormData();
        formData.append('module', 'curious_things');
        formData.append('action', 'list');

        const response = await customXhr(formData);

        if (response) {
            dispatch({ type: 'SET_CURIOUS_THINGS', payload: response });
        };
    };

    useEffect(() => {
        getCuriousThings();
    }, [status]);

    //Columns
    var columnT = ""

    columnT = [
        {
            name: "BAŞLIK",
            selector: (row) => row.title,
            sortable: true,
            cell: (row) => <a href="#" className="fw-bold text-primary">{row.title}</a>
        },
        {
            name: "YAZI",
            selector: (row) => row.text1,
            sortable: true
        },
        {
            name: "AKSİYON",
            selector: (row) => { },
            sortable: true,
            cell: (row) => <div className="btn-group" role="group" aria-label="Basic outlined example">
                <button
                    type="button"
                    className="btn btn-outline-secondary"
                    title="Türkçe Güncelleme"
                    onClick={() => { setIsUpdate(row); setLang("tur"); }}
                >
                    <i className="icofont-edit text-success"></i>
                </button>
                <button
                    type="button"
                    className="btn btn-outline-secondary"
                    title="İngilizce Güncelleme"
                    onClick={() => { setIsUpdate(row); setLang("eng"); }}
                >
                    <img src={process.env.PUBLIC_URL + `/flags/united-kingdom.png`} style={{ width: "15px" }} />
                </button>
                <button
                    type="button"
                    className="btn btn-outline-secondary"
                    title="Fransızca Güncelleme"
                    onClick={() => { setIsUpdate(row); setLang("fra"); }}
                >
                    <img src={process.env.PUBLIC_URL + `/flags/france.png`} style={{ width: "15px" }} />
                </button>
                <button
                    type="button"
                    className="btn btn-outline-secondary"
                    title="Almanca Güncelleme"
                    onClick={() => { setIsUpdate(row); setLang("ger"); }}
                >
                    <img src={process.env.PUBLIC_URL + `/flags/germany.png`} style={{ width: "15px" }} />
                </button>
                <button
                    type="button"
                    className="btn btn-outline-secondary"
                    title="Felemenkçe Güncelleme"
                    onClick={() => { setIsUpdate(row); setLang("dut"); }}
                >
                    <img src={process.env.PUBLIC_URL + `/flags/netherlands.png`} style={{ width: "15px" }} />
                </button>
                <button
                    type="button"
                    className="btn btn-outline-secondary deleterow"
                    title="Sil"
                    onClick={() => setIsDelete(row)}
                >
                    <i className="icofont-ui-delete text-danger"></i>
                </button>
            </div>,
            width: "300px"
        }
    ]

    return (
        <div className="container-xxl">
            <PageHeader headerTitle="Merak Edilenler" renderRight={() => {
                return <div className="col-auto d-flex w-sm-100">
                    <button className="btn btn-dark btn-set-task w-sm-100" onClick={() => { setIsModal(true) }}>
                        <i className="icofont-plus-circle me-2 fs-6"></i>
                        Yeni Öğe
                    </button>
                </div>
            }} />
            <div className="row clearfix g-3">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            {curiousThings &&
                                <DataTable
                                    title="Merak Edilenler"
                                    columns={columnT}
                                    data={[...curiousThings].reverse()}
                                    defaultSortField="title"
                                    pagination
                                    selectableRows={false}
                                    className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
                                    highlightOnHover={true}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={isModal}
                onHide={() => {
                    setIsModal(null);
                    setItem({
                        title: null,
                        text1: null,
                    });
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Yeni Öğe</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="title" className="form-label">Başlık</label>
                        <input
                            type="text"
                            className="form-control"
                            id="title"
                            value={item && item.title}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "title" ? "red" : "var(--border-color)"}` }}
                        />
                        {error.field === "title" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                    <div className="mb-3">
                        <label htmlFor="text1" className="form-label">Yazı</label>
                        <textarea
                            className="form-control"
                            id="text1"
                            rows="3"
                            value={item && item.text1}
                            onChange={handleOnChange}
                            style={{ borderColor: `${error.field === "text1" ? "red" : "var(--border-color)"}` }}
                        ></textarea>
                        {error.field === "text1" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleSubmit()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "tur"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Öğeyi Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="title" className="form-label">Başlık</label>
                        <input
                            type="text"
                            className="form-control"
                            id="title"
                            value={isUpdate && isUpdate.title}
                            onChange={handleOnUpdateChange}
                            style={{ borderColor: `${error.field === "title" ? "red" : "var(--border-color)"}` }}
                        />
                        {error.field === "title" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                    <div className="mb-3">
                        <label htmlFor="text1" className="form-label">Yazı</label>
                        <textarea
                            className="form-control"
                            id="text1"
                            rows="3"
                            value={isUpdate && isUpdate.text1}
                            onChange={handleOnUpdateChange}
                            style={{ borderColor: `${error.field === "text1" ? "red" : "var(--border-color)"}` }}
                        ></textarea>
                        {error.field === "text1" &&
                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleUpdate()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "eng"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">İngilizce Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="titleEng" className="form-label">Başlık</label>
                        <input
                            type="text"
                            className="form-control"
                            id="titleEng"
                            value={isUpdate && isUpdate.titleEng}
                            onChange={handleOnUpdateChange}
                            style={{ borderColor: `${error.field === "titleEng" ? "red" : "var(--border-color)"}` }}
                        />
                    </div>
                    {error.field === "titleEng" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                    <div className="mb-3">
                        <label htmlFor="textEng" className="form-label">Yazı 1</label>
                        <textarea
                            className="form-control"
                            id="textEng"
                            rows="5"
                            value={isUpdate && isUpdate.textEng}
                            onChange={handleOnUpdateChange}
                            style={{ borderColor: `${error.field === "textEng" ? "red" : "var(--border-color)"}` }}
                        ></textarea>
                    </div>
                    {error.field === "textEng" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleUpdate()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "fra"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Fransızca Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="titleFra" className="form-label">Başlık</label>
                        <input
                            type="text"
                            className="form-control"
                            id="titleFra"
                            value={isUpdate && isUpdate.titleFra}
                            onChange={handleOnUpdateChange}
                            style={{ borderColor: `${error.field === "titleFra" ? "red" : "var(--border-color)"}` }}
                        />
                    </div>
                    {error.field === "titleFra" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                    <div className="mb-3">
                        <label htmlFor="textFra" className="form-label">Yazı 1</label>
                        <textarea
                            className="form-control"
                            id="textFra"
                            rows="5"
                            value={isUpdate && isUpdate.textFra}
                            onChange={handleOnUpdateChange}
                            style={{ borderColor: `${error.field === "textFra" ? "red" : "var(--border-color)"}` }}
                        ></textarea>
                    </div>
                    {error.field === "textFra" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleUpdate()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "ger"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Almanca Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="titleGer" className="form-label">Başlık</label>
                        <input
                            type="text"
                            className="form-control"
                            id="titleGer"
                            value={isUpdate && isUpdate.titleGer}
                            onChange={handleOnUpdateChange}
                            style={{ borderColor: `${error.field === "titleGer" ? "red" : "var(--border-color)"}` }}
                        />
                    </div>
                    {error.field === "titleGer" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                    <div className="mb-3">
                        <label htmlFor="textGer" className="form-label">Yazı 1</label>
                        <textarea
                            className="form-control"
                            id="textGer"
                            rows="5"
                            value={isUpdate && isUpdate.textGer}
                            onChange={handleOnUpdateChange}
                            style={{ borderColor: `${error.field === "textGer" ? "red" : "var(--border-color)"}` }}
                        ></textarea>
                    </div>
                    {error.field === "textGer" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleUpdate()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={lang === "dut"}
                onHide={() => {
                    setIsUpdate(null);
                    setLang(null);
                    setError({
                        field: null,
                        note: null
                    });
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Felemenkçe Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="titleDut" className="form-label">Başlık</label>
                        <input
                            type="text"
                            className="form-control"
                            id="titleDut"
                            value={isUpdate && isUpdate.titleDut}
                            onChange={handleOnUpdateChange}
                            style={{ borderColor: `${error.field === "titleDut" ? "red" : "var(--border-color)"}` }}
                        />
                    </div>
                    {error.field === "titleDut" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                    <div className="mb-3">
                        <label htmlFor="textDut" className="form-label">Yazı 1</label>
                        <textarea
                            className="form-control"
                            id="textDut"
                            rows="5"
                            value={isUpdate && isUpdate.textDut}
                            onChange={handleOnUpdateChange}
                            style={{ borderColor: `${error.field === "textDut" ? "red" : "var(--border-color)"}` }}
                        ></textarea>
                    </div>
                    {error.field === "textDut" &&
                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="me-1"
                        onClick={() => handleUpdate()}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Yükleniyor...
                                </> :
                                <>
                                    Kaydet
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                centered
                show={isDelete}
                onHide={() => {
                    setIsDelete(null);
                    setIsLoading(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Uyarı</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <div className="form-label">
                            Bu öğeyi silmek istediğinize emin misiniz?
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="danger"
                        className="me-1 text-white"
                        onClick={() => handleDelete(isDelete.id)}
                        disabled={isLoading}
                    >
                        {
                            isLoading ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                    Siliniyor...
                                </> :
                                <>
                                    <i className="icofont-ui-delete"></i>
                                    &ensp;
                                    Sil
                                </>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default CuriousThings;